import axios from "axios";
import { Event } from "../Event";

export class FunctionEvents extends Event {
  // public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
  //   console.log("Valor do THIS :::>> ", this)
  //   const url = this.globalProps.match.url

  //   if(!url.includes("/add")) {
  //     const id = this.values.function_id;

  //     if(id === )
  //   }
  // }
}