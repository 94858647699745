import { Event } from "../../Event";
import dayjs, { Dayjs } from "dayjs";

interface IRequestLayoffExpectedDateDemissionHelper {
    calcByDayEmployee(params: { dateLayoff: string, dateAdmission: string }): void
}



export class RequestLayoffExpectedDateDemissionHelper extends Event implements IRequestLayoffExpectedDateDemissionHelper {
    private diffYearsAndDays(params: { dateLayoff: string; dateAdmission: string; }) {
        const startedDate = dayjs(params.dateAdmission);
        const endDate = dayjs(params.dateLayoff);

        const diffInDays = endDate.diff(startedDate, 'day');
        const years = endDate.diff(startedDate, 'year');

        const startDatePlusYear = startedDate.add(years, 'year');
        const diffInDaysWithoutYears = endDate.diff(startDatePlusYear, 'day');

        return {
            years,
            days: diffInDaysWithoutYears,
            totalDays: diffInDays
        };
    }

    calcByDayEmployee(params: { dateLayoff: string; dateAdmission: string; }): void {
        this.setState('loadings', [
            'request_layoff_company_time'
        ]);

        const getDiff = this.diffYearsAndDays(params);

        const yearText = getDiff.years === 1 ? 'ano' : 'anos';
        const dayText = getDiff.days === 1 ? 'dia' : 'dias';

        const displayValue = getDiff.years >= 1
            ? `${getDiff.years} ${yearText} e ${getDiff.days} ${dayText}`
            : `${getDiff.totalDays} ${getDiff.totalDays === 1 ? 'dia' : 'dias'}`;

        this.setFieldValue('request_layoff_company_time', displayValue);

        this.setState("loadings", []);
    }
}

