import { stubString } from "lodash"
import { Event } from "./../Event"
import dayjs from "dayjs"

export class UserHolidayEvents extends Event {
    public onLoad = () => {
        const isEdit = this.globalProps.isEdit;

        if (isEdit === null) {
            this.setState("loadings", ["user_holiday_total_balance"])

            this.setField("user_holiday_total_balance", {
                readonly: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 300)
        } else {
            this.setState("loadings", ["user_holiday_total_balance"])

            this.setField("user_holiday_total_balance", {
                readonly: true
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 300)
        }
    }
}

export class UserHolidayStartDateEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {

        // if (this.values?.user_holiday_remaining_balance) {

        // } else {
        //     if (this.values.user_holiday_start_date && this.values.user_holiday_end_date) {
        //         const totalBalance = this.values.user_holiday_total_balance

        //         if (totalBalance) {
        //             this.setState("loadings", ["user_holiday_remaining_balance"])

        //             this.setFieldValue("user_holiday_remaining_balance", totalBalance)

        //             setTimeout(() => {
        //                 this.setState("loadings", [])
        //             }, 350)
        //         }
        //     }
        // }

    }
}

export class UserHolidayEndDateEvents extends Event {
    // public onChange = (value: any, setField: any, setState: any, formValues: any) => {
    //     if (this.values.user_holiday_start_date && this.values.user_holiday_end_date) {
    //         const totalBalance = this.values.user_holiday_total_balance

    //         if (totalBalance) {
    //             this.setState("loadings", ["user_holiday_remaining_balance"])

    //             this.setFieldValue("user_holiday_remaining_balance", totalBalance)

    //             setTimeout(() => {
    //                 this.setState("loadings", [])
    //             }, 350)
    //         }
    //     }
    // }
}

export class UserHolidayTotalBalanceEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        const remainingBalance = this.values?.user_holiday_remaining_balance;

        if (!remainingBalance) {
            const totalBalance = this.values.user_holiday_total_balance

            if (totalBalance) {
                this.setState("loadings", ["user_holiday_remaining_balance"])

                this.setFieldValue("user_holiday_remaining_balance", totalBalance)

                setTimeout(() => {
                    this.setState("loadings", [])
                }, 350)
            }
        }
    }
}
