import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import DefaultPageAdmission from "../../processAdmission/DefaultPageAdmission";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";


export default function AdmissionRoutes(props: any) {
    const { path } = useRouteMatch();
    const location = useLocation();
    const key = '6LdY6VcqAAAAALdXOa_Ge6b2kfCYXi3Oq8BvlnUZ'; // Nova chave pública
    // 6LfktvUpAAAAAPZPgrqGBgTpllf8plUNC0iSZWJ8
    // 6LfktvUpAAAAAJ5AcuQDyhDIfM4eB1-eJC38sDZA


    return (
        <>
            <GoogleReCaptchaProvider
                reCaptchaKey={key}
                language="pt-br"
            >
                <Switch location={location}>
                    <Route
                        exact
                        path={path + '/:code'}
                        component={() => <DefaultPageAdmission />}
                    />
                </Switch>
            </GoogleReCaptchaProvider>
        </>
    )
}