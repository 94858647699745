import { Component } from "react";
import { ControlLabel, FormGroup, Notification, Button } from "rsuite";
import styles from "./styles.module.scss";

export class CopyLink extends Component<any, {}> {
    handleCopyLink = () => {
        const link = this.props.values?.digital_admission_generation_link

        if (link) {
            navigator.clipboard.writeText(link);
            Notification.success({
                title: "Sucesso",
                description: "Link copiado para a área de transferência!"
            });
        } else {
            Notification.success({
                title: "Sucesso",
                description: "Não há link para ser copiado para a área de transferência!"
            });
        }
    };

    render() {
        return (
            <FormGroup>
                <ControlLabel>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '95%',
                            height: '20px'
                        }}
                    ></div>
                </ControlLabel>
                <div className={styles.containerButton}>
                    <Button
                        className={styles.button}
                        appearance="primary"
                        color="orange"
                        onClick={this.handleCopyLink}
                    >
                        Copiar Link!
                    </Button>
                </div>
            </FormGroup>
        );
    }
}
