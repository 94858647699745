import React from "react";
import { withRouter } from "react-router";
import { ModuleContext, UserContext } from "../components/authProvider/main";
import { LoginAnimationSvg } from "../svg/LoginAnimationSvg";
import LogoPng from "../assets/mind.png";
import { ModuleBlock } from "./modules/ModuleBlock";
import { CSSTransition } from "react-transition-group";
import config from "../globals";
import { ModuleContainer } from "./modules/ModuleContainer";
import { Helmet } from "react-helmet";
import axios from "../utilities/axios";
import { Placeholder } from "rsuite";

const { Paragraph } = Placeholder;
class ModulesPage extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;

    public constructed = false;

    public state = {
        isMounted: false,
        configModules: []
    };

    public nodeRef: any = null;

    constructor(props: any) {
        super(props);
        this.nodeRef = React.createRef();
    }

    componentDidUpdate() {
    }


    // componentWillUnmount = () => {
    //     this.setState({isMounted:false})
    // }

    componentDidMount = () => {
        this.setState({ isMounted: true });
        // if (this.props.match.path === "/dashboard" && (this.context.name !== null || this.context.name !== undefined)) {
        //     // this.context.setModule(null,null);
        // }
    };


    goTo(link: string) {
        // this.context.setModule(link.split('/').pop());
        this.props.history.push(link);
    }

    renderModules = (variableName = "modules") => {
        const configModule = this.context.data.modulesReturn;
        var output = [];
        let index = 0;
        for (const i in configModule) {
            let moduleData: any = configModule[i];
            index += 1.5;
            if(moduleData.module_visible_dashboard) {
                output.push(
                    <ModuleBlock
                        key={i}
                        visible={moduleData.module_visible_dashboard}
                        color={moduleData.module_color}
                        delay={index}
                        onClick={() => this.goTo("/dashboard/" + moduleData.module_url)}
                        icon={moduleData.module_icon}
                        name={moduleData.module_label}
                    />
                );
            }
        }
        return output;
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>Brasmeg RH</title>
                </Helmet>

                <CSSTransition nodeRef={this.nodeRef} in={this.state.isMounted} timeout={200} unmountOnExit classNames="fade">
                    <div className="modules-page">
                        <LoginAnimationSvg />
                        <div className="side-logo">
                            <img src={LogoPng} alt="" />
                        </div>
                        {/* <ModuleContainer size={2} title="NOVO">
                            {this.renderModules("main")}
                        </ModuleContainer> */}
                        <ModuleContainer size={3} title="Módulos">
                            {this.context.data ? (
                                <>
                                    {this.renderModules()}
                                </>
                            ) : (
                                <>
                                    <Paragraph />
                                </>
                            )}
                        </ModuleContainer>
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default withRouter(ModulesPage);
