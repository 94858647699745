import React from "react";
import { Uploader } from "rsuite";
import axiosExternal from "../../utilities/axiosExternal";
import _ from "lodash";

interface IUploadFileExternalProps { 
    guid: string;
    columnName: string;
    relation: string;
    onChange: (files: any) => void;
}

type TMetaFile = {
    file_absolute_path: string;
    file_extension: string;
    file_fk_relation_id: string;
    file_name: string;
    file_path: string;
    file_relation_field: string;
    file_relation_table: string;
    file_size: number;
    file_type: string;
}

type TFiles = {
    exists: boolean;
    id: number;
    meta: TMetaFile;
    path: string;
}


type TUploadFileExternalState = {
    files: TFiles[]
}


export class UploadFileExternal extends React.Component<IUploadFileExternalProps, TUploadFileExternalState> {
    state: TUploadFileExternalState = {
        files: []
    }


    onRemove = (file: any) => {
        const { files } = this.state;
        let fileId = null;
        for(const item of files) {
            if(item.meta.file_name === file.name) {
                fileId = item.id
            }
        }

        axiosExternal
            .post("/admission/file/delete/" + fileId)
            .then((res) => { 

            })
            .catch((e) => {
                // const files: any[] = this.state.files;
                // files.splice(index, 0, file);
                // this.setState({ files });
            });
        return this.state.files;
    };

    onSuccess(res: any) {
        const { status, ...responseParams } = res;
        const merged = [...this.state.files, {...responseParams}]
        this.setState(
            {
                files: merged
            }
        )
        this.props.onChange(merged)
    }


    render(): React.ReactNode {
        return (
            <>
                <Uploader
                    action={"/admission/files/upload?guid=" + this.props.guid + "&relation=" + this.props.relation + "&field=" + this.props.columnName + '&token=' + localStorage.getItem('admissionCode')}
                    headers={{
                        Authorization: "bearer " + localStorage.getItem('admissionCode'),
                    }}
                    multiple
                    onRemove={this.onRemove}
                    onSuccess={this.onSuccess = this.onSuccess.bind(this)}
                    listType="picture-text"
                >
                    <div
                        style={
                            {
                                background: 'rgba(0, 0, 0, 0.1)',
                                width: '100%'
                            }
                        }
                    >
                        Anexe os arquivos solicitados aqui!
                    </div>
                </Uploader>
            </>
        )
    }
}
