import { Event } from "../Event";

export class FilledChecklistEvents extends Event {

    public onLoad = async () => {
        if (this.globalProps.match.url.includes("/view")){
            this.setField("filled_checklist_checklist", {hidden: false})
        }

    }

}