import { Event } from "../Event";

export class BaseEvents extends Event {
    public onLoad = () => {
        const hasExtraInitalHour = this.values?.base_fixed_hour_extra

        this.setState("loadings", ["base_amount_hour"])

        if(hasExtraInitalHour) {
            this.setField("base_amount_hour", {
                hidden: false
            })
        } else {
            this.setField("base_amount_hour", {
                hidden: true
            })
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 250)
    }
}