import { AxiosResponse } from "axios";
import axios from "../../utilities/axios";
import { Event } from "../Event";

export class RequestChangePositionEmployeeFkUserIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState('loadings', [
                'request_change_position_admission_date',
                'request_change_position_manager_fk_user_id'
            ]);

            let managerId: any;
            let managerName: string = "";

            try {
                const userResponse = await axios.get('/api/v1/users/' + Number(value));
                const { item } = userResponse.data;

                managerId = item?.user_manager;
                if (item.user_admission_date) {
                    this.setFieldValue('request_change_position_admission_date', item.user_admission_date);
                }

                if (managerId) {
                    const managerResponse = await axios.get("/api/v1/users/" + Number(managerId));
                    managerName = managerResponse.data?.item?.user_name;

                    this.setFieldValue("request_change_position_manager_fk_user_id", { value: managerId, label: managerName });
                }


            } catch (error) {
                console.error('Error fetching user or manager data', error);
            } finally {
                this.setState('loadings', []);
            }

            setTimeout(() => {
                this.setState("loadings", []);
            }, 250);
        }
    };

}