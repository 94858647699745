import React from "react";
import { Button, Notification } from "rsuite";
import axios from "../../utilities/axios";
import { AxiosResponse } from "axios";


interface UpdateReproveStatusButtonProps {
    requestSituationId: number;
    jobRequestId: number;
    foreignKey: number;
    reloadPage?: () => void;
    textButton: string;
    userFunction: number | null;
    userReproveDescription: string | null;
    onSubmit: any
}

type State = {
    textButton: string;
    loading: boolean;
    userFunction: number | null;
    userReproveDescription: string | null;

}

export class UpdateReproveStatusButton extends React.Component<UpdateReproveStatusButtonProps, State> {
    state = {
        textButton: this.props.textButton,
        loading: false,
        userFunction: this.props.userFunction,
        userReproveDescription: this.props.userReproveDescription

    }

    componentDidMount(): void {
        // this.selectIsText()
    }


    // selectIsText = () => {
    //     let textButton = ''
    //     switch (this.props.requestSituationId) {
    //         case 2: 
    //             textButton = "Aprovação Diretor"
    //         break
    //         case 3:
    //             textButton = "Aprovação CEO"
    //         break;
    //     }

    //     if(textButton !== '') {
    //         this.setState({ textButton })
    //     }

    // }

    updateSituation = () => {
        this.setState(
            {
                loading: true
            }
        )

        const regex: RegExp = /<p>(.*?)<\/p>/;
        const userReproveDescription: string | null = this.props.userReproveDescription;

        if (!userReproveDescription) {
            Notification.error({
                title: "Erro",
                description: "Preencha a justificatica de reprovação!"
            })

            this.setState({ loading: false })
        } else {
            const match: RegExpMatchArray | null = userReproveDescription.match(regex);

            const requestSituations = [1, 2, 3]

            if (requestSituations.includes(this.props.requestSituationId) && match && match[1]) {
                this.props.onSubmit(
                    () => {
                        axios
                            .get('/api/v1/situations/request_layoff/update/reprove', {
                                params: {
                                    currentId: this.props.requestSituationId,
                                    foreignKey: this.props.foreignKey,
                                    userFunction: this.props.userFunction
                                }
                            })
                            .then((res: AxiosResponse) => {
                                if (res.status === 200) {
                                    if (this.props.reloadPage) {
                                        this.props.reloadPage()
                                    }
                                }
                            })
                            .finally(() => {
                                this.setState({ loading: false })
                            })
                    })
            } else {

                // Notification.error({
                //     title: "Erro",
                //     description: "Preencha a justificatica de reprovação!"
                // })

                this.setState({ loading: false })
            }
        }

    }

    render(): React.ReactNode {
        return (
            <>
                <Button
                    color="red"
                    onClick={this.updateSituation}
                    loading={this.state.loading}
                >
                    {this.state.textButton}
                </Button>
            </>
        )
    }
}