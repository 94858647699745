import { Event } from "../Event";

export class ParameterImportFkCompanyIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState("loadings", ["parameter_import_fk_base_id"])

            this.setFieldValue("parameter_import_fk_base_id", "")

            this.setField("parameter_import_fk_base_id", {
                readonly: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}