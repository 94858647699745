import { RequestLayoffExpectedDateDemissionHelper } from "./RequestLayoffExpectedDateDemissionHelper";

export class RequestLayoffExpectedDateDemissionEvents extends RequestLayoffExpectedDateDemissionHelper {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.calcByDayEmployee(
            {
                dateLayoff: value,
                dateAdmission: this.globalProps.values.request_layoff_admission_date
            }
        )

    }
}