import { Event } from "../Event";

export class UserChecklistEvents extends Event {

    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value){
            this.setField("user_checklist_tables", {required: true, hidden: false})
        } else {
            this.setField("user_checklist_tables", {hidden: true})
        }
    }

}