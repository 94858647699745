import axios from "../../../utilities/axios";
import { Event } from "../../Event";
import { RequestLayoffEmployeeFkUserIdHelper } from "./RequestLayoffEmployeeFkUserIdHelper";


export class RequestLayoffEmployeeFkUserIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState("loadings", ["request_layoff_manager_fk_user_id", "request_layoff_position_fk_function_id", "request_layoff_admission_date"])

            const userInformations: any = await axios.get("/api/v1/request_layoff/get_user_information/" + value)


            if (userInformations) {
                const functionId = userInformations.data?.function_id
                // const functionDescription = userInformations.data?.function_description
                const userAdmissionDate = userInformations.data?.user_admission_date
                const managerId = userInformations.data?.user_manager_id
                // const managerName = String(userInformations.data?.user_manager_name)


                // this.setFieldValue("request_layoff_position_fk_function_id", String(userInformations?.function_id))
                this.setFieldValue("request_layoff_position_fk_function_id", functionId)
                this.setFieldValue("request_layoff_admission_date", userAdmissionDate)
                this.setFieldValue("request_layoff_manager_fk_user_id", managerId ? managerId : undefined)

                setTimeout(() => {
                    this.setState("loadings", [])
                }, 250)
            } else {
                setTimeout(() => {
                    this.setState("loadings", [])
                }, 250)
            }

            // this.setField("request_layoff_manager_fk_user_id", {
            //     readonly: false
            // })

            // const instancedClass = new RequestLayoffEmployeeFkUserIdHelper(
            //     {
            //         thisSetFunction: this
            //     }
            // );
            // await instancedClass.setUser({ value: value }).then(() => {
            //     instancedClass.setFunctionAndAdmissionDate({ value: value });
            // })
        }
    }
}