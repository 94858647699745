import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from "rsuite";
import { FormatedPlate } from "../../core/interfaces/interfaces";
import { MethodsUtils } from "../../core/utils/MethodsUtils";

export class VehiclePlateEvents extends Event {
    onBlur = async (value: any, setField: any, setState: any) => {

        if (value) {
            this.setState("loadings", ["vehicle_plate"])
            const formatedPlate: FormatedPlate = await MethodsUtils.formatAndValidatePlate(value);

            if (formatedPlate.valid){
                this.setFieldValue("vehicle_plate", formatedPlate.formatted)
            } else {
                this.setFieldValue("vehicle_plate", "");
                Notification.error({
                    title: "ERRO",
                    description: formatedPlate.message,
                    duration: 8000
                })
            }

            this.setState("loadings", []);
        }

    }
}