import { Component, ReactNode } from "react";
import { DatePicker, Input, InputGroup, PanelProps, Table } from "rsuite";
import { CheckedCell } from "./CheckdCell";

import { NumberInput } from "../../NumberInput";


const { Cell, Column, HeaderCell } = Table;

interface IPanelInstallmentParcelProps extends PanelProps {
    data: Record<string, any>[];
    actionTable: ({ value, consigned_credit_installment_id }: { value: boolean, consigned_credit_installment_id: number }) => void;
    formValues: any
}


export class TableInstallmentParcel extends Component<IPanelInstallmentParcelProps, any> {
    renderCheckColumn = ({ rowData }: { rowData: Record<string, any> }) => {


        return <CheckedCell
            value={rowData.consigned_credit_installment_installment_paid}
            rowData={rowData}
            handleActionOnChange={this.props.actionTable}
            formValues={this.props.formValues}
        />;
    }

    checkIfDisabled = ({ rowData }: { rowData: Record<string, any> }): boolean => {
        let booleanReturn = false;


        if (rowData.consigned_credit_installment_installment_paid) {
            booleanReturn = true
        }

        // if (rowData.consigned_credit_installment_installment_paid) {
        //     booleanReturn = true;
        // }
        // if (this.props.disabledAll) {
        //     booleanReturn = true;
        // }

        return booleanReturn;
    }


    render(): ReactNode {
        return (
            <Table
                data={this.props.data}
                autoHeight
            >
                <Column flexGrow={1}>
                    <HeaderCell>Parcela</HeaderCell>
                    <Cell dataKey="consigned_credit_installment_installment_number" />
                </Column>
                <Column width={250}>
                    <HeaderCell>Valor da Parcela</HeaderCell>
                    {/* <Cell>
                        {
                            (rowData: Record<string, any>) => {
                                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.consigned_credit_installment_parcel_value);
                            }
                        }
                    </Cell> */}
                    <Cell dataKey="consigned_credit_installment_parcel_value" >
                        {(rowData: any, rowIndex: any) => {
                            return (
                                <>
                                    <div
                                        style={{ marginTop: "-7px" }}
                                    >

                                        <NumberInput
                                            disabled={this.checkIfDisabled(
                                                {
                                                    rowData
                                                }
                                            )}
                                            preffix={"R$"}
                                            punctuation={false}
                                            step={2}
                                            onChange={(value: any) => {
                                                this.props.data[rowIndex].consigned_credit_installment_parcel_value = value;
                                            }}
                                            value={rowData.consigned_credit_installment_parcel_value}
                                        />
                                    </div>
                                </>
                            );
                        }}
                    </Cell>
                </Column>
                <Column width={200}>
                    <HeaderCell>Vencimento</HeaderCell>
                    {/* <Cell>
                        {
                            (rowData: Record<string, any>) => {
                                return new Date(rowData.consigned_credit_installment_parcel_date).toLocaleDateString();
                            }
                        }
                    </Cell> */}
                    <Cell dataKey="consigned_credit_installment_parcel_date" >
                        {(rowData: any, rowIndex: any) => {
                            return (
                                <>
                                    <DatePicker
                                        disabled={this.checkIfDisabled(
                                            {
                                                rowData
                                            }
                                        )}
                                        style={{ marginTop: "-7px" }}
                                        format="DD/MM/YYYY"
                                        defaultValue={new Date(rowData.consigned_credit_installment_parcel_date)}
                                        onChange={(e) => {
                                            this.props.data[rowIndex].consigned_credit_installment_parcel_date = e;
                                        }}
                                    />
                                </>
                            );
                        }}
                    </Cell>
                </Column>
                <Column width={200}>
                    <HeaderCell>Já foi pago?</HeaderCell>
                    <Cell>
                        {(rowData: Record<string, any>) => (
                            this.renderCheckColumn({ rowData })
                        )}
                    </Cell>
                </Column>
            </Table>
        )
    }
}