import { Component, ReactNode } from "react";
import { DatePicker } from 'rsuite';
import dayjs from "dayjs";

interface IDatePickerProps {
    onChange: (value: any) => void;
    value: Date;
    readonly?: boolean;
}

interface IDatePickerState {
    value: Date;
}

const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'OK',
    today: 'Hoje',
    yesterday: 'Ontem',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos'
}



export class DatePickerComponent extends Component<IDatePickerProps, IDatePickerState> {
    state = {
        value: this.props.value
    }

    componentDidUpdate(props: any, state: any): void {
        if (props.value !== state.value) {
            this.setState({ value: props.value })
        }
    }

    handleOnChange = (value: Date) => {
        const formattedDate = dayjs(value).format('YYYY-MM-DD HH:mm:ss.SSS');
        this.props.onChange(formattedDate);
        this.setState({ value: value });
    }



    render(): ReactNode {
        return (
            <>
                <DatePicker
                    format="DD/MM/YYYY"
                    locale={locale}
                    value={this.state.value}
                    onChange={this.handleOnChange}
                    onClean={() => this.props.onChange(null)}
                    disabled={this.props.readonly}
                    oneTap
                    style={
                        {
                            display: "flex"
                        }
                    }
                />
            </>
        )
    }
}