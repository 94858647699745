import { Event } from "../Event";
import { Notification } from "rsuite";

export class RequestChangePositionManagerFkUserIdEvents extends Event {
    // public onChange = (value: any, setField: any, setState: any, formValues: any) => {
    //     if (value) {
    //         console.log("this :::::::>> ", this)
    //         const functionaryId = this.values?.request_change_position_employee_fk_user_id

    //         if (value === functionaryId) {
    //             this.setState("loadings", ["request_change_position_manager_fk_user_id"])

    //             Notification.error({
    //                 title: "Erro!",
    //                 description: "Esse usuário já está selecionado como funcionário!"
    //             })

    //             this.setFieldValue("request_change_position_manager_fk_user_id", "")

    //             setTimeout(() => {
    //                 this.setState("loadings", [])
    //             }, 250)
    //         }
    //     }
    // }
}