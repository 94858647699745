import { Event } from "../Event";

export class GeneralParameterImportCompanyExtraHoursAmountEvents extends Event {
    // public onChange = (value: any, setField: any, setState: any, formValues: any) => {
    //     console.log(value)
    // }

    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState("loadings", ["general_parameter_import_company_extra_hours_show_grid"])

            const paddedTime = value.padStart(6, '0')

            const hours = paddedTime.slice(0, 2);
            const minutes = paddedTime.slice(2, 4);
            const seconds = paddedTime.slice(4, 6);

            const formattedHour = `${hours}:${minutes}:${seconds}`

            this.setFieldValue("general_parameter_import_company_extra_hours_show_grid", formattedHour)

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}