import { Event } from "../Event";
import { CreateScreen } from "./utilities/CreateScreen";
import { DisciplinaryMeasuresTypes } from "../../core/enums/DisciplinaryMeasuresEnum";
import { read } from "fs";

export class DisciplinaryMeasureEvents extends Event {
    public onLoad = () => {
        const url = this.globalProps.match.url

        if (!url.includes("/add")) {
            this.setState("loadings", ["disciplinary_measure_fk_disciplinary_measure_type_id"])

            this.setField("disciplinary_measure_fk_disciplinary_measure_type_id", {
                readonly: true
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }

        const isEdit = this.globalProps.isEdit
        const selectTypeValue = this.values?.disciplinary_measure_fk_disciplinary_measure_type_id

        if (selectTypeValue) {
            const createScreen = new CreateScreen({
                thisSet: this
            })

            switch (selectTypeValue) {
                case DisciplinaryMeasuresTypes.Article:
                    createScreen.screenArticle();
                    break;
                case DisciplinaryMeasuresTypes.Suspension:
                    createScreen.screenSuspension();
                    break;
                case DisciplinaryMeasuresTypes.Advertence:
                    createScreen.screenAdvertence();
                    break;
            }
        }

        if (selectTypeValue == 2 || selectTypeValue == 3) {
            this.setState("loadings", ["disciplinary_measure_file_attachment"])
            this.setField("disciplinary_measure_file_attachment", {
                order: 200
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 500)
        }

        if (selectTypeValue === 2) {
            this.setState("loadings", ["disciplinary_measure_suspension_text"])
            this.setField("disciplinary_measure_suspension_text", {
                order: 190
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 500)
        }
    }
}