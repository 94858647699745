import React, { ReactNode } from "react";
import { Table, Modal, Button, Input, Toggle, Notification } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import { FileInput } from "../../formfields/FileInput";
import axios from "../../../utilities/axiosReport";
import CustomRedCell from "./CustomRedCell";
import TablePagination from "rsuite/lib/Table/TablePagination";
import { ButtonToolbar } from 'rsuite';

import styles from "./customStyles/styles.module.scss";

const { Column, HeaderCell, Cell, Pagination } = Table;

interface ModalImportAttachmentsProps {
    title?: string;
    crud: string;
    show: boolean;
    onCLoseModal: () => void;
    functionNameImport: string;
}

type State = {
    // showModal: boolean;
    uid: string;
    loading: boolean;
    importedData: any[];
    showDataModal: boolean;
    displayLength: number;
    page: number;
    filter: string;
    statusFilter: string;
    itemsData: any[];
    showConferenceModal: boolean;
    loadingButton: boolean;
    filterColor: string;
    conferenceItems: any[];
    sectors: any[];
    functions: any[];
    adjustmentTable: boolean;
}


const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}


export class ModalImportAttachmentManager extends React.Component<ModalImportAttachmentsProps, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            uid: uuidv4(),
            loading: false,
            importedData: [],
            showDataModal: false,
            displayLength: 10,
            page: 1,
            filter: '',
            statusFilter: '',
            itemsData: [],
            showConferenceModal: false,
            loadingButton: false,
            filterColor: '',
            conferenceItems: [],
            sectors: [],
            functions: [],
            adjustmentTable: false
        };

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeLength = this.handleChangeLength.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this)
        this.handleStatusFilterChange = this.handleStatusFilterChange.bind(this)
        this.handleCleanFilter = this.handleCleanFilter.bind(this)
    }

    handleChangePage(dataKey: any) {
        this.setState({
            page: dataKey
        });
    }
    handleChangeLength(dataKey: any) {
        this.setState({
            page: 1,
            displayLength: dataKey
        });
    }

    handleFilterChange(value: string) {
        this.setState({ statusFilter: '' })
        this.setState({ filter: value })
    }

    handleToggleChange(rowData: any, rowIndex: any) {
        const updatedData = this.state.importedData.map((item: any) => {
            if (item.user_name === rowData.user_name) {
                if (item.user_is_insert) {
                    return { ...item, user_is_insert: false };

                } else {
                    return { ...item, user_is_insert: true };
                }
            }
            return item;
        });

        this.setState({ importedData: updatedData });
    }

    handleStatusFilterChange(status: string) {
        this.setState({ statusFilter: status })
    }

    cancelImport() {
        this.setState({
            importedData: [],
            showDataModal: false
        })

        this.onCLoseModal()
    }

    async confirmImport() {
        const data = this.state.importedData;

        const response = await axios.post("/api/v1/import/manager", {
            params: {
                data
            }
        });

        this.setState({
            showConferenceModal: false,
            showDataModal: false,
            loadingButton: false
        })

        this.onCLoseModal()

        Notification.success({
            title: "Sucesso!",
            description: "Importação finalizada com sucesso!"
        })

        window.location.reload()
    }

    handleCleanFilter() {
        this.setState({ statusFilter: '', filter: '' })
    }

    handleFilterColor(color: string) {
        this.setState({ filterColor: color })
    }

    onChangeValues = ({ value, guid }: { value?: any, guid: string }) => {
        this.setState(
            {
                uid: guid
            }
        )
    }

    onCLoseModal = () => {
        this.props.onCLoseModal();
    }

    onProcessArchive = () => {
        this.setState({ loading: true })

        axios
            .get(`/api/v1/import/${this.props.crud}/${this.state.uid}/${this.props.functionNameImport}`)
            .then((res: any) => {
                this.setState({
                    loading: false,
                    importedData: res.data?.result,
                    showDataModal: true
                })
            })

    }

    renderModal = () => {
        if (!this.state.adjustmentTable) {
            setTimeout(() => {
                this.forceUpdate()
                this.setState({
                    adjustmentTable: true
                })
            }, 1000)
        }

        const { importedData, showDataModal, displayLength, page, filter, statusFilter, adjustmentTable } = this.state;

        const verifyImportedData = importedData ? importedData : []

        const filteredData = verifyImportedData.filter((item: any) => {
            const matchesFilter = filter === '' || Object.values(item).some(value =>
                String(value).toLowerCase().includes(filter.toLowerCase())
            );
            return matchesFilter
        })

        const data = filteredData.filter((v, i) => {
            const start = displayLength * (page - 1);
            const end = start + displayLength;
            return i >= start && i < end;
        });

        return (
            <Modal
                full
                show={showDataModal}
                // onHide={() => this.setState({ showDataModal: false })}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>
                    <Modal.Title>
                        Conferência de Importação de Gestor
                        <br />
                        <span style={{ color: 'red' }}>*Os registros não marcados como adicionar serão atualizados!</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        placeholder="Filtrar..."
                        value={filter}
                        onChange={this.handleFilterChange}
                        style={{ marginBottom: '15px' }}
                    />

                    <Table
                        height={400}
                        data={data}
                        locale={{ emptyMessage: "Nenhum registro novo/alterado" }}
                    >
                        <Column width={150}>
                            <HeaderCell>Status</HeaderCell>
                            <Cell dataKey="user_status" />
                        </Column>

                        <Column width={250} resizable>
                            <HeaderCell>Informações</HeaderCell>
                            <Cell dataKey="user_information" />
                        </Column>

                        <Column width={125}>
                            <HeaderCell>Código</HeaderCell>
                            <Cell dataKey="user_code" />
                        </Column>

                        <Column width={350}>
                            <HeaderCell>Nome</HeaderCell>
                            <Cell dataKey="user_name" />
                        </Column>

                        <Column width={150}>
                            <HeaderCell>CPF</HeaderCell>
                            <Cell dataKey="user_cpf_cnpj" />
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Admissão</HeaderCell>
                            <Cell dataKey="formatted_admission_date" />
                        </Column>

                        <Column width={200}>
                            <HeaderCell>Demissão</HeaderCell>
                            <Cell dataKey="formatted_inactive_date" />
                        </Column>

                        {/* <Column width={200}>
                            <HeaderCell>Tipo Permissão</HeaderCell>
                            <Cell dataKey="user_permission_description" />
                        </Column> */}

                        <Column width={150}>
                            <HeaderCell>Cód. Função</HeaderCell>
                            <Cell dataKey="user_function_code" />
                        </Column>

                        <Column width={325}>
                            <HeaderCell>Função</HeaderCell>
                            <Cell dataKey="user_function_name" />
                        </Column>

                        <Column width={325}>
                            <HeaderCell>Setor</HeaderCell>
                            <Cell dataKey="user_department_name" />
                        </Column>

                        <Column width={250}>
                            <HeaderCell>Empresa</HeaderCell>
                            <Cell dataKey="user_company_name" />
                        </Column>

                        <Column width={250}>
                            <HeaderCell>Base</HeaderCell>
                            <Cell dataKey="user_base" />
                        </Column>

                        <Column width={250}>
                            <HeaderCell>Salário</HeaderCell>
                            <Cell dataKey="user_custom_salary" />
                        </Column>

                        {/* <Column width={100}>
                            <HeaderCell>Adicionar?</HeaderCell>
                            <Cell>
                                {(rowData: any, rowIndex: any) => {
                                    return rowData.user_need_column ? (
                                        <Toggle
                                            checked={rowData.someToggleValue}
                                            onChange={() => this.handleToggleChange(rowData, rowIndex)}
                                        />
                                    ) : null;
                                }}
                            </Cell>
                        </Column> */}

                    </Table>

                    <TablePagination
                        lengthMenu={[
                            {
                                value: 10,
                                label: 10
                            },
                            {
                                value: 20,
                                label: 20
                            },
                            {
                                value: 50,
                                label: 50
                            },
                            {
                                value: 100,
                                label: 100
                            }
                        ]}
                        activePage={page}
                        displayLength={displayLength}
                        total={importedData.length}
                        onChangePage={this.handleChangePage}
                        onChangeLength={this.handleChangeLength}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ marginTop: '15px' }} appearance="primary" color="red" onClick={() => {
                        this.cancelImport()
                        this.setState({ showDataModal: false })
                    }}>Cancelar Importação</Button>


                    <Button loading={this.state.loadingButton} style={{ marginTop: '15px' }} appearance="primary" color="green" onClick={() => {
                        this.setState({ loadingButton: true })
                        this.confirmImport()
                    }}>Confirmar Importação</Button>

                </Modal.Footer>
            </Modal>
        );
    }

    render(): ReactNode {

        return (
            <Modal
                show={this.props.show}
            >
                <Modal.Title>
                    Importação {this.props?.title}
                </Modal.Title>
                <Modal.Body>
                    <FileInput
                        multiple={false}
                        guid={this.state.uid}
                        showQuestions={false}
                        readonly={false}
                        name={`${this.props.functionNameImport}_uploader`}
                        relation={this.props.crud}
                    // onChangeCustom={({value,guid}) => this.onChangeValues({value,guid})}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={this.onCLoseModal}
                    >Fechar</Button>
                    {this.state.uid !== '' && (
                        <Button
                            color="green"
                            onClick={
                                this.onProcessArchive
                            }
                            loading={this.state.loading}
                        >
                            Processar
                        </Button>
                    )}
                </Modal.Footer>
                {this.state.showDataModal && this.renderModal()}
            </Modal>
        )
    }
}