import axios from "../../../utilities/axios";

export async function getUserFunction(userId: number | string) {
    const response = await axios.get("/api/v1/control_qualification/get_user_function/" + userId)

    if (response) {
        return {
            value: response.data?.function_id,
            label: response.data?.function_description
        }
    } else {
        return null
    }
}