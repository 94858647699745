import { Event } from "../Event";

export class RequestLayoffFkBaseIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState("loadings", ["request_layoff_employee_fk_user_id"])

            this.setField("request_layoff_employee_fk_user_id", {
                readonly: false
            })

            this.setFieldValue("request_layoff_employee_fk_user_id", "")

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}