import React from "react";
import { Button } from "rsuite";
import axios from "../../utilities/axios";
import { AxiosResponse } from "axios";


interface UpdateUnmakeStatusButtonProps {
    requestSituationId: number;
    jobRequestId: number;
    foreignKey: number;
    reloadPage?: () => void;
    textButton: string;
    userFunction: number | null;
}

type State = {
    textButton: string;
    loading: boolean;
    userFunction: number | null;
}

export class UpdateUnmakeStatusButton extends React.Component<UpdateUnmakeStatusButtonProps, State> {
    state = {
        textButton: this.props.textButton,
        loading: false,
        userFunction: this.props.userFunction
    }

    componentDidMount(): void {
        // this.selectIsText()
    }


    // selectIsText = () => {
    //     let textButton = ''
    //     switch (this.props.requestSituationId) {
    //         case 2: 
    //             textButton = "Aprovação Diretor"
    //         break
    //         case 3:
    //             textButton = "Aprovação CEO"
    //         break;
    //     }

    //     if(textButton !== '') {
    //         this.setState({ textButton })
    //     }

    // }

    updateSituation = () => {
        this.setState(
            {
                loading: true
            }
        )
        axios
            .get('/api/v1/situations/request_change_position/update/unmake', {
                params: {
                    currentId: this.props.requestSituationId,
                    foreignKey: this.props.foreignKey,
                    userFunction: this.props.userFunction
                }
            })
            .then((res: AxiosResponse) => {
                if (res.status === 200) {
                    if(this.props.reloadPage) {
                        this.props.reloadPage()
                    }
                }
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    render(): React.ReactNode {
        return (
            <>
                <Button
                    color="violet"
                    onClick={this.updateSituation}
                    loading={this.state.loading}
                >
                    {this.state.textButton}
                </Button>
            </>
        )
    }
}