import { Event } from "../Event";

export class ChecklistUserEvents extends Event {
    public onLoad = () => {

        if (this.globalProps.values.user_checklist){
            this.setField("user_checklist_tables", {required: true, hidden: false})

        } else {
            this.setField("user_checklist_tables", {hidden: true})
        }
        
    }
}