import React from "react";
import { Table, SelectPicker, Loader, CheckPicker } from "rsuite";
import axios from "../../../utilities/axios";
import _, { result } from "lodash";

export class SelectCustom extends React.Component<any, {}> {
  public state: any = {
    loading: false,
    data: []
  }

  async getStores() {
    this.setState({ loading: true })
    await axios.get('api/v1/stores')
      .then((res: any) => {
        this.setState({
          data: res.data.data
        })
      })
      .catch((e) => console.log(e.message))
      .finally(() => this.setState({ loading: false }))
  }

  updateData = () => {
    if (this.state.data.length === 0) {
      this.getStores();
    }
  };

  renderMenu = (menu: any) => {
    if (this.state.loading) {
      return (
        <p style={{ padding: 4, color: '#999', textAlign: 'center' }}>
          <Loader /> Carregando...
        </p>
      );
    }
    return menu;
  };

  componentDidMount() {
    this.getStores()
  }
  
  handleSelectChange = (value: any) => {
    this.setState({ selectedValue: value });
    this.props.onChange(value); 
  };

  componentDidUpdate(prevProps: any, prevState: any) {

  }

  render() {
    const { data } = this.state
    return (
      <div style={{ display: "flex", flexDirection: 'column', alignItems: 'stretch', width: '100%' }}>
        <p>Loja Solicitante</p>
        <SelectPicker
          onChange={this.handleSelectChange}
          placeholder="Selecione..."
          data={data}
          // value={user}
          style={{ marginBottom: 30 }}
          onOpen={this.updateData}
          onSearch={this.updateData}
          renderMenu={this.renderMenu}
        />
      </div>
    )
  }
}