import { Event } from "../Event";

export class JobRequestFkCompanyIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState("loadings", ["job_request_fk_base_id"])

            this.setField("job_request_fk_base_id", {
                readonly: false
            })

            this.setFieldValue("job_request_fk_base_id", "")

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}