import { Event } from "../Event";

export class ControlQualificationEvents extends Event {
    public onLoad = () => {
        const url = this.globalProps.match.url

        if (!url.includes("/add")) {
            const functionary = this.values?.control_qualification_fk_user_id

            if (functionary) {
                this.setState("loadings", ["control_qualification_fk_user_id"])

                this.setField("control_qualification_fk_user_id", {
                    readonly: false
                })

                setTimeout(() => {
                    this.setState("loadings", [])
                }, 250)
            }
        }
    }
}