import { extend } from "lodash";
import { Event } from "../Event";
import axios from './../../utilities/axios'

export class CompanyEmailEvents extends Event{
    // public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
    //     // console.log("Entrei no onLoad")
    // }
}

export class CompanyEmailMainEmailEvents extends Event{
    // public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
    //     // console.log("Valor do onChange", value)
    // };
}