import React, { ReactNode } from "react";
import { Button, Modal } from "rsuite";
import { FileInput } from "../formfields/FileInput";
import axios from "../../utilities/axios";
import { AxiosError, AxiosResponse } from "axios";


interface ModalImportAttachmentsProps {
    title?: string;
    crud: string;
    show: boolean;
    onCloseModal: () => void;
    onProcessArchive?: (params: { data: Record<string,any>}) => void;
    functionNameImport: string;
    modalBody?: ReactNode;
    allAttributes?: Record<string, any>;
}

type State = {
    // showModal: boolean;
    uid: string;
    loading: boolean;
}

const uuidv4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}


export class ModalImportAttachments extends React.Component<ModalImportAttachmentsProps, State> {
    public state = {
        // showModal: false
        uid: uuidv4(),
        loading: false
    }


    onProcessArchive = () => {
        this.setState({ loading: true })
        axios
            .get(`/api/v1/import/${this.props.crud}/${this.state.uid}/${this.props.functionNameImport}`, 
                {
                    params: {
                        attributes: this.props.allAttributes
                    }
                }
            )
            .then((response: AxiosResponse<{result: {
                uniqueId: string;
            }}>) => {
                if(this.props.onProcessArchive) {
                    this.props.onProcessArchive(
                        {
                            data: response.data.result,
                        }
                    )
                }
            })
            .catch((e: AxiosError) => {

            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }


    onCloseModal = () => {
        this.props.onCloseModal();
    }
    render(): ReactNode {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.onCloseModal}
            >
                <Modal.Title>
                    Importação {this.props?.title}
                </Modal.Title>
                <Modal.Body>
                    <div
                        style={
                            {
                                display: 'flex',
                                flexDirection: "column",
                                gap: 10
                            }
                        }
                    >
                        {this.props.modalBody && (
                            <>
                                {this.props.modalBody}
                            </>
                        )}
                        <FileInput
                            multiple={false}
                            guid={this.state.uid}
                            showQuestions={false}
                            readonly={false}
                            name={`${this.props.functionNameImport}_uploader`}
                            relation={this.props.crud}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        onClick={this.onCloseModal}
                    >Fechar</Button>
                    {this.state.uid !== '' && (
                        <Button
                            color="green"
                            onClick={this.onProcessArchive}
                            loading={this.state.loading}
                        >
                            Processar
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        )
    }
}
