//IMPORTAR O COMPONENTE
import { CreateLambdaComplement } from "./CreateLambdaComplement";
import { TransferRequestCustom } from "./transferRequest/TransferRequestCustom";
import { ReportCustom } from "./ReportCustom";
import { CheckTreePickerUserRestrict } from './user/CheckTreePickerUserRestrict'
import { SelectCustom } from "./SelectCustom";
import { CheckTreePickerProjectUser } from "./project/CheckTreePickerProjectUser";
import { TransferRequestCollectUser } from "./transferRequest/TransferRequestCollectUser";
import { TransferRequestUser } from "./transferRequest/TransferRequestUser";
import { TransferRequestDataArrivedVuupt } from "./transferRequest/TransferRequestDataArrivedVuupt";
import { CheckTreePickerAllOption } from "./CheckTreePickerAllOption";
import { CreateTablesComplement } from "./CreateTablesComplement";
import { CompanyEmailTest } from './companyEmail/CompanyEmailTest'
import { CustomInstallment } from "./EntryExpenseReleased/CustomInstallment";
import { CustomInstallmentConsigned } from "./consignedCredits/CustomInstallmentConsigned";
import { ManagerSelectCustom } from "../../users/ManagerSelectCustom";
import SalaryInput from "../../users/SalaryInput";
import { InputSelectManyEmails } from "./generalParameters/InputSelectManyEmails";

import { SelectCustomSector } from "../../jobRequest/SelectCustomSector";
import { SelectCustomSelectChangePosition } from "../../requestChangePosition/SelectCustomSelectChangePosition";
import { SelectCustomSectorRequestLayoff } from "../requestLayoffs/SelectCustomSectorRequestLayoff";
import { CopyLink } from "../../digitalAdmission/CopyLink";
import ChecklistHistory from "./checklistHistory/index";

//CheckList History


// EXPORTAR O COMPONENTE

export default {
    ChecklistHistory,
    CheckTreePickerAllOption,
    CheckTreePickerProjectUser,
    CheckTreePickerUserRestrict,
    CompanyEmailTest,
    CopyLink,
    CreateLambdaComplement,
    CreateTablesComplement,
    CustomInstallment,
    CustomInstallmentConsigned,
    InputSelectManyEmails,
    ManagerSelectCustom,
    ReportCustom,
    SalaryInput,
    SelectCustom,
    SelectCustomSector,
    SelectCustomSectorRequestLayoff,
    SelectCustomSelectChangePosition,
    TransferRequestCollectUser,
    TransferRequestCustom,
    TransferRequestDataArrivedVuupt
};
