import { Component, ReactNode } from "react";
import { ControlLabel, FormGroup, SelectPicker } from "rsuite";
import axios from "../../utilities/axios";

interface StateProps {
    selectData: any[];
    selectedUser: any;
    selectedValue: any;
}

interface PropsP {
    values: {
        user_select_companies: any;
        user_select_sectors: any;
        user_manager: string;
    },
    history: {
        location: {
            pathname: string;
        };
    };
}

export class ManagerSelectCustom extends Component<PropsP, StateProps> {
    state: StateProps = {
        selectData: [],
        selectedUser: '',
        selectedValue: ''
    };

    componentDidMount() {
        this.getRegisteredData();
    }

    handleSelect = (value: any) => {

        this.setState({
            selectedUser: value,
            selectedValue: value
        });

        this.props.values.user_manager = value;
    };

    getRegisteredData = async () => {
        const selectedValue = this.props.values?.user_manager;

        if (selectedValue) {
            try {
                const response = await axios.get("/api/v1/users/get_user_name/" + selectedValue);

                this.setState({
                    selectedValue: response.data.value
                });

                await this.handleGetData();
                // await this.handleGetData();
            } catch (error) {
                console.error("Erro ao buscar dados do usuário:", error);
            }
        } else {
            await this.handleGetData();
        }
    };

    handleGetData = async () => {
        const selectCompanies = this.props.values?.user_select_companies;
        const selectSectors = this.props.values?.user_select_sectors;

        const url = this.props.history.location.pathname;

        let companyIds: number[] = [];
        let sectorIds: number[] = [];

        if (selectCompanies && selectSectors) {
            if (url.includes("/add")) {
                companyIds = selectCompanies.map((item: any) => item.user_select_company_value ? item.user_select_company_value : item);
                sectorIds = selectSectors.map((item: any) => item.user_select_sector_value ? item.user_select_sector_value : item)
            } else {
                companyIds = selectCompanies.map((item: any) => item.user_select_company_value);
                sectorIds = selectSectors.map((item: any) => item.user_select_sector_value);

                if (!companyIds[0] || !sectorIds[0]) {
                    companyIds = selectCompanies.map((item: any) => item.user_select_company_value);
                    sectorIds = selectSectors.map((item: any) => item.user_select_sector_value);
                }
            }

            try {

              const response = await axios.post("/api/v1/users/get_select_data", {
                    params: {
                        company_ids: companyIds,
                        sector_ids: sectorIds
                    }
                });

                this.setState({
                    selectData: response.data
                });
            } catch (error) {
                console.error("Erro ao buscar os dados:", error);
            }
        }
    };

    render(): ReactNode {
        const { selectedValue, selectData } = this.state;

        return (
            <FormGroup>
                <ControlLabel>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '95%'
                        }}
                    >
                        Gerente
                    </div>
                </ControlLabel>

                <div className="rs-form-control-wrapper">
                    <SelectPicker
                        data={selectData}
                        value={selectedValue}
                        onChange={this.handleSelect}
                        onOpen={this.handleGetData}
                        style={{ width: '100%' }}
                    />
                </div>
            </FormGroup>
        );
    }
}
