import React from "react";
import { Button, Checkbox, ControlLabel, ErrorMessage, FormGroup, Input, Tag, TagGroup, Toggle, Placeholder } from "rsuite";
import { UploadFileExternal } from "./UploadFileExternal";
import classNames from "classnames";
import _, { values } from "lodash";
import { RecaptchaComponent } from "./RecaptchaComponent";
import { MaskInput } from "../formfields/MaskInput";
import styles from "./styles/styles.module.scss";

export interface OnSubmitParams {
    formValues: Record<string, any>
}

interface FormSubmitProps {
    form: Record<string, any>[];
    onSubmit: (params: OnSubmitParams) => void;
    guid: string;
    relation: string;
    formLabelAttachment: Record<string, any>[];
    loading: boolean;
    errors: Record<string, any>;
}

type TFormSubmitState = {
    formValues: Record<string, any>[]
}

export class FormSubmit extends React.Component<FormSubmitProps, TFormSubmitState> {
    constructor(props: FormSubmitProps) {
        super(props);
        this.state = {
            formValues: []
        }
    }

    fieldSetValues({ columnName, value }: { columnName: string; value: any; }) {
        this.setState(
            {
                formValues: { ...this.state.formValues, [columnName]: value }
            }
        )
    };

    renderError = (fieldName: string) => {
        if (Object.keys(this.props.errors).includes(fieldName)) {
            return (
                <ErrorMessage show={true} placement={"bottomStart"}>
                    <span>{this.props.errors[fieldName]}</span>
                </ErrorMessage>
            );
        }
        return null;
    };

    renderFields({ field, columnName }: { field: Record<string, any>, columnName: string }) {
        let outputHtmlField: React.ReactElement = <></>;
        const { formLabelAttachment } = this.props;
        switch (field.type) {
            case 'text':
                outputHtmlField = (
                    <FormGroup>
                        <ControlLabel>
                            <label>{field.label}</label>
                        </ControlLabel>
                        <div className="rs-form-control-wrapper">
                            <MaskInput
                                allowLowercase={field.allowLowercase}
                                disabled={field.readonly || field.disabled}
                                password={field.password ? field.password.toString() : "false"}
                                password_character={field.password_character}
                                name={field.name}
                                maxLength={field.maxlength ?? undefined}
                                minLength={field.minlength ?? undefined}
                                preffix={field.preffix}
                                suffix={field.suffix}
                                onChangeEnd={(value: string, e: any) => {
                                    this.fieldSetValues(
                                        {
                                            columnName: field.name,
                                            value: value
                                        }
                                    )
                                }}
                                required={field.required}
                                regex={field.regex}
                                mask={field.disabledMask == true ? undefined : field.mask}
                            />
                            {field?.required && (
                                <p className={styles.required}>*Obrigatório</p>
                            )}
                        </div>
                    </FormGroup>
                )
                break;
            case "checkbox":
                outputHtmlField = (
                    <FormGroup className={styles.checkboxContainer}>
                        <ControlLabel>
                            <label className={styles.label}>{field.label}</label>
                        </ControlLabel>
                        <div className={styles.rsFormControlWrapper}>
                            <Toggle
                                onChange={(value: boolean) => {
                                    this.fieldSetValues(
                                        {
                                            columnName: field.name,
                                            value: value
                                        }
                                    )
                                }}
                                className={styles.checkbox}
                            />
                            {field?.required && (
                                <p className={styles.required}>*Obrigatório</p>
                            )}
                        </div>
                    </FormGroup>
                )
                break;
            case 'file':
                const filterTags = _.filter(formLabelAttachment, { 'column': columnName })[0]
                outputHtmlField = (
                    <FormGroup>
                        <ControlLabel className={styles.fileControlLabel}>
                            <label>{field?.label}</label>
                            {filterTags?.values.length > 0 && (
                                <TagGroup>
                                    {filterTags.values.map((values: Record<string, any>) => {
                                        return (
                                            <Tag color="blue">{values.document_name}</Tag>
                                        )
                                    })}
                                </TagGroup>
                            )}
                        </ControlLabel>
                        <div className="rs-form-control-wrapper">
                            <UploadFileExternal
                                guid={this.props.guid}
                                relation={this.props.relation}
                                columnName={field.name}
                                onChange={(files: any) => {
                                    this.fieldSetValues(
                                        {
                                            columnName: field.name,
                                            value: files
                                        }
                                    )
                                }}
                            />
                            {field?.required && (
                                <p className={styles.required}>*Obrigatório</p>
                            )}
                        </div>
                    </FormGroup>
                )
        }
        return (
            <fieldset
                id={field.name}
                className={styles.container}
                style={{
                    width: `${field.width * 8.12}%`,
                    display: field.hiddenExternal === true ? "none" : "block"
                }}
            >
                {outputHtmlField}
            </fieldset>
        )
    }

    renderContent() {
        const outputRenderAllHtml: React.ReactElement[] = [];
        const { form } = this.props;
        const orderByForm = _.orderBy(form, ['order'], ['asc']);
        for (const column in orderByForm) {
            const columnProperty = orderByForm[column];
            outputRenderAllHtml.push(
                this.renderFields({ field: columnProperty, columnName: orderByForm[column].name })
            );
        }
        return outputRenderAllHtml
    }

    render(): React.ReactNode {
        return (
            <>
                {Object.keys(this.props.form).length > 0 && (
                    <>
                        <div className={styles.formContainer}>
                            <form className={styles.form}>
                                {this.renderContent()}
                            </form>
                            <div className={styles.containerLGPDText}>
                                <h3>AVISO DE PRIVACIDADE</h3>

                                <span>
                                    <p style={{ textIndent: '60px', textAlign: 'justify' }}>Todas as operações de tratamento de dados da Brasmeg Transportes estão alinhadas com as hipóteses previstas na LGPD, as chamadas bases legais de tratamento de dados pessoais. Estas hipóteses são:</p>
                                    <ul>
                                        <li>I. Mediante o fornecimento de consentimento pelo titular;</li>
                                        <li>II. Para o cumprimento de obrigação legal ou regulatória pela empresa</li>
                                        <li>III. Quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato do qual seja parte o titular, a pedido do titular dos dados;</li>
                                        <li>IV. Para o exercício regular de direitos em processo judicial, administrativo ou arbitral, esse último nos termos da Lei nº 9.307, de 23 de setembro de 1996 (Lei de Arbitragem);</li>
                                        <li>V. Para a proteção da vida ou da incolumidade física do titular ou de terceiros;</li>
                                        <li>VI. Quando necessário para atender aos interesses legítimos da empresa ou de terceiros, exceto no caso de prevalecerem direitos e liberdades fundamentais do titular que exijam a proteção dos dados pessoais;</li>
                                        <li>VII. Para a proteção do crédito, inclusive quanto ao disposto na legislação pertinente.</li>
                                    </ul>
                                </span>

                                <h3>CLÁUSULA DE TRATAMENTO DE DADOS PESSOAIS</h3>

                                <span style={{ textIndent: '60px' }}>Coleta de Dados. O candidato reconhece que a EMPREGADORA, para fins de persecução das suas atividades ou cumprimento de obrigações legais e em consonância com a Lei Geral de Proteção de Dados (LGPD), irá coletar dados de natureza pessoal por meio de seus equipamentos, sistemas, colaboradores e outros meios lícitos para obtenção dessas informações. Tais dados pessoais podem ser compartilhados com empresas dentro do mesmo grupo empresarial e econômico da Empresa ou fornecidos a autoridades mediante ordem judicial. Além disso os dados poderão ser compartilhados com empresas de benefícios (plano de saúde e/ou odontológico; vale refeição e/ou alimentação; vale transporte, seguro de vida etc.) a serem concedidos ao(a) EMPREGADO(A).</span>
                            </div>
                        </div>
                        <div className={styles.submitContainer}>
                            <RecaptchaComponent
                                onSubmit={() => this.props.onSubmit({ formValues: this.state.formValues })}
                                loading={this.props.loading}
                            />
                        </div>
                    </>
                )}
            </>
        )
    }
}
