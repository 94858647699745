import styles from "./styles.module.scss";
import React, { useState } from "react";
import { Button, Icon, Input, InputGroup, Notification, Panel, PanelGroup } from "rsuite";
import { setCookie, parseCookies, destroyCookie } from "nookies";
import { ArrowLeftLine, Paragraph } from "@rsuite/icons";
import axios from "axios";
import dayjs from "dayjs";
import { MethodsUtils } from "../../../../core/utils/MethodsUtils";

interface ListChecklistsPageState {
    userName: string;
    checklists: any;
    filteredChecklists: any;
}

interface CustomInputGroupWidthButtonProps {
    placeholder: string;
    // Add any other props you expect here
}

const CustomInputGroupWidthButton: React.FC<CustomInputGroupWidthButtonProps> = ({ placeholder, ...props }) => (
    <InputGroup {...props} inside style={styles}>
        <Input placeholder={placeholder} />
        <InputGroup.Button>
            <Icon icon="search" />
        </InputGroup.Button>
    </InputGroup>
);

class ListChecklistsPage extends React.Component<any, ListChecklistsPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            userName: "",
            checklists: [],
            filteredChecklists: []
        };
    }

    handleReturnLogin(): void {
        window.location.href = '/checklist';
    }

    async handleIncludeChecklist(): Promise<void> {
        const cookie = parseCookies()
        const userInformations = cookie.checklist_driver_name
        // console.log({ userInformations })

        if (userInformations) {
            const parseUserInformations = JSON.parse(userInformations)
            const unformattedCPF = parseUserInformations?.cpfCpnj.replace(/\D/g, '');

            let lastPlate: string = ""

            const lastVehicle = await axios.get("/checklist/getLastVehicle/" + unformattedCPF)

            if (lastVehicle.status === 200) {
                lastPlate = MethodsUtils.FormatPlate(lastVehicle.data.message);
            }

            setCookie(null, "@lastVehicle", lastPlate, {
                maxAge: 60 * 60,
                path: '/',
            })
        }

        window.location.href = '/checklist/register';
    }

    async componentDidMount(): Promise<void> {
        const cookies = parseCookies()
        const cookieValue = cookies.checklist_driver_name;

        if (cookies["@startChecklist"]) {
        setCookie(null, "@startChecklist", "", {
            maxAge: 0,
            path: '/',
        });
    }

        if (!cookieValue) {
            window.location.href = '/checklist';
            return;
        }

        const userObj = JSON.parse(cookieValue)

        const responseFind = await axios.get("/checklist/find/" + userObj.id)

        if (responseFind.data.checklist) {
            const formattedInformations = responseFind.data.checklist.map((item: any) => {
                const formatDate = dayjs(item?.createdAt).format("DD/MM/YYYY")

                return {
                    ...item,
                    formattedCreatedAt: formatDate
                }
            })

            this.setState({
                checklists: formattedInformations,
                filteredChecklists: formattedInformations
            })
        }

        this.setState({
            userName: userObj.name
        })
    }

    handleSearchChecklist = (inputValue: string) => {
        const searchValue = inputValue.toLowerCase();
        const filteredChecklists = this.state.checklists.filter((item: any) =>
            item.checklistName.toLowerCase().includes(searchValue) ||
            item.checklistVehicle.toLowerCase().includes(searchValue) ||
            item.checklistType.toLowerCase().includes(searchValue) ||
            item.checklistCode?.toString().toLowerCase().includes(searchValue) ||
            item?.formattedCreatedAt.includes(searchValue)
        );
        this.setState({ filteredChecklists });
    };


    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.headerContainer} onClick={this.handleReturnLogin}>
                        <ArrowLeftLine className={styles.arrow} onClick={this.handleReturnLogin} />
                        <span className={styles.returnPageText}>Voltar ao Início</span>
                    </div>
                    <h1 className={styles.userName}>{this.state.userName}</h1>
                </div>
                <div className={styles.container}>
                    <div className={styles.mainContainer}>
                        <div className={styles.headerButtonsContainer}>
                            <Button
                                appearance="primary"
                                color="cyan"
                                className={styles.includeButton}
                                onClick={this.handleIncludeChecklist}
                            >
                                Incluir Checklist
                            </Button>
                            {/* <CustomInputGroupWidthButton placeholder="Buscar Checklist..." /> */}
                            <InputGroup inside style={styles}>
                                <Input placeholder="Buscar Checklist..." onChange={inputValue => this.handleSearchChecklist(inputValue)} />
                                <InputGroup.Button>
                                    <Icon icon="search" />
                                </InputGroup.Button>
                            </InputGroup>
                        </div>
                        <div className={styles.checklistsContainer}>
                            {this.state.filteredChecklists && this.state.filteredChecklists.length > 0 ? (
                                this.state.filteredChecklists.map((item: any) => (
                                    <Panel header={`Documento ${item?.checklistCode}`} collapsible bordered key={item?.checklistCode}>
                                        <div className={styles.cardText}>
                                            <span><strong>Status:</strong> Concluído</span>
                                            <span><strong>Veículo:</strong> {item?.checklistVehicle}</span>
                                            <span><strong>Tipo: </strong> {item?.checklistType}</span>
                                            <span><strong>Motorista:</strong> {item?.checklistName}</span>
                                            <span><strong>Data:</strong> {item?.formattedCreatedAt}</span>
                                        </div>
                                    </Panel>
                                ))
                            ) : (
                                <div>Nenhum checklist encontrado.</div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ListChecklistsPage;
