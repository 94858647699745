import { Event } from "../Event";

export class HolidaySolicitationFkCompanyIdEvents extends Event {
    // public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
    //     console.log("Teste!")
    //     // const idSelectedCompany = Number(value)

    //     // if (idSelectedCompany) {
    //     //     this.setState("loadings", ["holiday_solicitation_funcionary_fk_user_id"])

    //     //     this.setField("holiday_solicitation_funcionary_fk_user_id", {
    //     //         disabled: false
    //     //     })

    //     //     setTimeout(() => {
    //     //         this.setState("loadings", [])
    //     //     }, 400)
    //     // }
    // }
}