import { MethodsUtils } from "../../../../core/utils/MethodsUtils";
import { Event } from "../../../Event";
import { Notification } from "rsuite";

export class UserHourStartEvents extends Event {

     public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            value = value.slice(0, 2) + ':' + value.slice(2);

            // DA PARA DEIXAR ESSA VALIDAÇÃO DINÂMICA, SEM A NECESSIDADE DE REPETIR CÓDIGO,
            // MAS EU QUIS DEIXAR ASSIM: 30/09/2024 - ELIAS

            if (await MethodsUtils.isValidHour(value)) {
                this.calculatedInterval(setField, setState, formValues);

            } else {
                Notification.warning({
                    title: "ATENÇÃO",
                    description: "Horário do Início da Jornada Inválido!",
                    duration: 2500
                })
                value = "08:00"
                this.setFieldValue("user_hour_start", value);
                this.calculatedInterval(setField, setState, formValues);
            }
        }
     }

     async calculatedInterval(setField: any, setState: any, formValues: any) {
            const intervalCalculated: string = await MethodsUtils.intervalCalculated(formValues.user_hour_end ,formValues.user_hour_start, formValues.user_hour_interval);
            this.setState("loadings", "user_amount_hour_day");
            this.setFieldValue("user_amount_hour_day", MethodsUtils.removeCharString(intervalCalculated, ":",""));
            this.setState("loadings", []);
     }

}