import { Event } from "../Event";
import { getUserFunction } from "./utils/getUserFunction";

export class ControlQualificationFkUserIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ["control_qualification_fk_function_id"])

        if (value) {
            const formattedFunction = await getUserFunction(value)

            this.setFieldValue("control_qualification_fk_function_id", formattedFunction)
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 250)
    }
}