import React from "react";
import { withRouter } from "react-router-dom";
import { Event } from "../Event";
import { Button, ButtonToolbar, Icon, Notification } from "rsuite";
import axios from "../../utilities/axios";
import dayjs from "dayjs";

type State = {
    formValues: any;
    loading: boolean;
}

class HolidaySolicitationFooterComponent extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            formValues: this.props.values.formValues,
        };
    }

    showOverlay() {
        const overlay = document.createElement("div");
        overlay.id = "overlay";
        overlay.style.position = "fixed";
        overlay.style.top = "0";
        overlay.style.left = "0";
        overlay.style.width = "100%";
        overlay.style.height = "100%";
        overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
        overlay.style.zIndex = "999"; // Garantir que o overlay fique acima de outros elementos
        document.body.appendChild(overlay);
    }

    removeNotification = () => {
        Notification.closeAll();
    };

    cancelSolicitation = async () => {
        const initialDate = dayjs(this.props.values.formValues?.holiday_solicitation_initial_date);

        if (initialDate) {
            const today = dayjs();
            const date20DaysBefore = initialDate.subtract(20, 'days');

            // Verifica se a data de hoje é após o limite de 20 dias antes do início das férias
            if (today.isAfter(date20DaysBefore, 'day')) {
                Notification.error({
                    title: "Erro!",
                    description: "O Cancelamento só é permitido ser feito até 20 dias antes do início das férias.",
                    placement: "topEnd",
                });
            } else {
                Notification.open({
                    title: (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon
                                icon="exclamation-circle"
                                size="lg"
                                style={{ marginRight: "5px", color: "red" }}
                            />
                            <h5 style={{ margin: 0 }}>Confirmar Cancelamento</h5>
                        </div>
                    ),
                    description: (
                        <div>
                            <h6>Tem certeza que deseja cancelar esta solicitação de férias?</h6>
                            <br />
                            <ButtonToolbar>
                                <Button appearance="primary" onClick={() => {
                                    this.props.onSubmit(async () => {
                                        const holidaySolicitationId = this.props.values.formValues?.holiday_solicitation_id;

                                        if (holidaySolicitationId) {
                                            try {
                                                const res = await axios.post("/api/v1/holiday_solicitation/cancel_solicitation", {
                                                    params: {
                                                        id: holidaySolicitationId
                                                    }
                                                });
                                            } catch (error) {
                                                console.error("Error cancelling holiday solicitation :::::> ", error);
                                            }
                                        }
                                    });

                                    Notification.closeAll()
                                }}
                                >
                                    SIM
                                </Button>
                                <Button
                                    color="red"
                                    onClick={() => {
                                        Notification.closeAll();
                                    }}
                                >
                                    NÃO
                                </Button>
                            </ButtonToolbar>
                        </div>
                    ),
                    onClose: (() => this.removeNotification()),
                    duration: 100000000,
                });
            }
        }
    }

    render(): React.ReactNode {
        const isActive = this.props.values.formValues?.holiday_solicitation_active

        // const initialVacationDate = this.props.values.formValues?.holiday_solicitation_initial_date
        // const date120DaysBefore = initialDate.subtract(120, 'days');

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                    marginLeft: 8
                }}
            >
                {!isActive && (
                    <Button
                        color="red"
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            gap: 10,
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={this.cancelSolicitation}
                    >
                        <i className="fas fa-times"></i>
                        Cancelar Solicitação
                    </Button>
                )}
            </div>
        );
    }
}

const HolidaySolicitationFooterWithRouter = withRouter(HolidaySolicitationFooterComponent);

export class HolidaySolicitationFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <HolidaySolicitationFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}