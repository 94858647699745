import React from "react";
import styles from "./styles.module.scss";
import { ControlLabel, FormGroup, Placeholder, TagPicker } from 'rsuite';

interface Props {
    values: {
        general_parameter_email_email_to_send: any;
        general_parameter_email_show_grid_email: any;
        general_parameter_email_email_show_grid_aso: any;
        general_parameter_email_email_show_grid_license: any;
    };
}

interface State {
    emails: string[];
    data: { label: string; value: string }[];
    emailsAso: string[];
    dataAso: { label: string; value: string }[];
    emailsLicense: string[];
    dataLicense: { label: string; value: string }[];
}

export class InputSelectManyEmails extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            emails: [],
            data: [],
            emailsAso: [],
            dataAso: [],
            emailsLicense: [],
            dataLicense: []
        };
    }

    componentDidMount(): void {
        const registeredEmails = this.props.values?.general_parameter_email_email_to_send;
        const registeredEmailsAso = this.props.values?.general_parameter_email_email_show_grid_aso;
        const registeredEmailsLicense = this.props.values?.general_parameter_email_email_show_grid_license;

        if (registeredEmails) {
            const emailsArray = registeredEmails.split(",");
            const emailData = emailsArray.map((email: string) => ({ label: email, value: email }));
            this.setState({ emails: emailsArray, data: emailData });
        }

        if (registeredEmailsAso) {
            const emailsAsoArray = registeredEmailsAso.split(",");
            const emailDataAso = emailsAsoArray.map((email: string) => ({ label: email, value: email }));
            this.setState({ emailsAso: emailsAsoArray, dataAso: emailDataAso });
        }

        if (registeredEmailsLicense) {
            const emailsLicenseArray = registeredEmailsLicense.split(",");
            const emailDataLicense = emailsLicenseArray.map((email: string) => ({ label: email, value: email }));
            this.setState({ emailsLicense: emailsLicenseArray, dataLicense: emailDataLicense });
        }
    }

    handleChangeEmails = (emails: string[]) => {
        const { data } = this.state;
        const newEmails = emails.filter(email => !data.some(item => item.value === email));
        const updatedData = [...data, ...newEmails.map(email => ({ label: email, value: email }))];
        
        this.setState({ emails, data: updatedData }, () => {
            this.props.values.general_parameter_email_email_to_send = this.state.emails.join(",");
            this.props.values.general_parameter_email_show_grid_email = this.state.emails.join(",");
        });
    };

    handleChangeEmailsAso = (emailsAso: string[]) => {
        const { dataAso } = this.state;
        const newEmailsAso = emailsAso.filter(email => !dataAso.some(item => item.value === email));
        const updatedDataAso = [...dataAso, ...newEmailsAso.map(email => ({ label: email, value: email }))];
        
        this.setState({ emailsAso, dataAso: updatedDataAso }, () => {
            this.props.values.general_parameter_email_email_show_grid_aso = this.state.emailsAso.join(",");
        });
    };

    handleChangeEmailsLicense = (emailsLicense: string[]) => {
        const { dataLicense } = this.state;
        const newEmailsLicense = emailsLicense.filter(email => !dataLicense.some(item => item.value === email));
        const updatedDataLicense = [...dataLicense, ...newEmailsLicense.map(email => ({ label: email, value: email }))];
        
        this.setState({ emailsLicense, dataLicense: updatedDataLicense }, () => {
            this.props.values.general_parameter_email_email_show_grid_license = this.state.emailsLicense.join(",");
        });
    };

    render() {
        return (
            <>
                <div className={styles.container}>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "95%",
                            }}
                            className={styles.labelText}
                        >
                            Notificações de Férias
                        </div>
                        <TagPicker
                            data={this.state.data}
                            style={{ width: '100%' }}
                            placeholder="Adicione ou selecione e-mails para notificação sobre férias..."
                            value={this.state.emails}
                            onChange={this.handleChangeEmails}
                            creatable
                        />
                    </div>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "95%",
                            }}
                            className={styles.labelText}
                        >
                            Notificações de ASO Periódico
                        </div>
                        <TagPicker
                            data={this.state.dataAso}
                            style={{ width: '100%' }}
                            placeholder="Adicione ou selecione e-mails para notificação sobre ASO periódico..."
                            value={this.state.emailsAso}
                            onChange={this.handleChangeEmailsAso}
                            creatable
                        />
                    </div>
                    <div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                width: "95%",
                            }}
                            className={styles.labelText}
                        >
                            Notificações de Vencimento de Habilitação
                        </div>
                        <TagPicker
                            data={this.state.dataLicense}
                            style={{ width: '100%' }}
                            placeholder="Adicione ou selecione e-mails para notificação de vencimento de habilitação..."
                            value={this.state.emailsLicense}
                            onChange={this.handleChangeEmailsLicense}
                            creatable
                        />
                    </div>
                </div>
            </>
        );
    }
}
