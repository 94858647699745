import axios from "../../utilities/axios";
import { Event } from "../Event";

export class UserIsManagerEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ["user_fk_base_id", "user_bases"])

        if (value) {
            this.setField("user_bases", {
                hidden: false,
                required: true
            })

            this.setField("user_fk_base_id", {
                hidden: true
            })

            // this.setFieldValue("user_fk_base_id", undefined)
        } else {
            this.setField("user_bases", {
                hidden: true,
                required: false
            })

            this.setField("user_fk_base_id", {
                hidden: false,
                readonly: false
            })

            // setTimeout(() => {
            //     this.setState("loadings", [])
            // }, 250)

            // if (userId) {
            //     await axios.delete("/api/v1/users/delete_bases/" + userId)
            // }
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 250)
    }
}