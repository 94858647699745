import axios from "../../utilities/axios";
import { Event } from "../Event";

export class JobRequestEvents extends Event {
    public onLoad = async () => {
        const situation = this.values.job_request_fk_request_situation_id;
        const url = this.globalProps.match.url;

        if (url.includes("/add")) {
            const resposne = await axios.get("/api/v1/me");
            const me = resposne.data.user;
            const userFunction = me.user_management_fk_function_id;

            if (userFunction == 4) {
                this.setState("loadings", ["job_request_wage", "job_request_previous_date"])

                this.setField("job_request_wage", {
                    required: true
                })

                this.setField("job_request_previous_date", {
                    required: true
                })

                setTimeout(() => {
                    this.setState("loadings", [])
                }, 400)
            } else {
                // console.log("Cai no else")
            }
        } else {
            this.setState("loadings", ["job_request_fk_base_id", "job_request_fk_user_id"])

            this.setField("job_request_fk_base_id", {
                readonly: false
            })

            this.setField("job_request_fk_user_id", {
                readonly: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }

        const situationsToCondition = [1, 2, 6]

        if (situationsToCondition.includes(situation)) {
            this.setState("loadings", ['job_request_reason_disapproval'])

            this.setField("job_request_reason_disapproval", {
                hidden: false,
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 300)
        }
    }
}