import { Event } from "../Event";

export class RequestChangePositionFkBaseIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        const company = this.values?.request_change_position_fk_company_id

        if (company) {
            if (value) {
                this.setState("loadings", ["request_change_position_employee_fk_user_id"])

                this.setField("request_change_position_employee_fk_user_id", {
                    readonly: false
                })

                this.setFieldValue("request_change_position_employee_fk_user_id", "")

                setTimeout(() => {
                    this.setState("loadings", [])
                }, 250)

            }
        }

    }
}