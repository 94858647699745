import axios from "../../utilities/axios";
import { Event } from "../Event";

export class RequestChangePositionEvents extends Event {
    public onLoad = async () => {
        const url = this.globalProps.match.url;

        if (url.includes("/add")) {

        } else {
            this.setState("loadings", ["request_change_position_fk_base_id", "request_change_position_manager_fk_user_id"])

            this.setField("request_change_position_fk_base_id", {
                readonly: false
            })

            this.setField("request_change_position_manager_fk_user_id", {
                readonly: false
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }

        const userId = this.values.request_change_position_employee_fk_user_id;

        if (userId) {
            const userSalary = await axios.get("/api/v1/request_change_position/get_salary/" + userId)

            if (userSalary.data) {
                this.setState("loadings", ["request_change_position_current_wage"])

                this.setFieldValue("request_change_position_current_wage", userSalary.data)

                setTimeout(() => {
                    this.setState("loadings", [])
                }, 300)
            }
        }

        const situation = this.values.request_change_position_fk_request_situation_id

        const situationsToCondition = [1, 2, 6]

        if (situationsToCondition.includes(situation)) {
            this.setState("loadings", ['request_change_position_reason_disapproval'])

            this.setField("request_change_position_reason_disapproval", {
                hidden: false,
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 300)
        }
    }
}