import { Event } from "../Event";

export class HolidaySolicitationEvents extends Event {
    public onLoad = () => {
        const url = this.globalProps.match.url;
        const isCanceled = this.values?.holiday_solicitation_active

        this.setState("loadings", ["holiday_solicitation_acquisition_periods", "holiday_solicitation_text_date"])

        if (!url.includes("/add")) {
            if (isCanceled) {
                this.setState("loadings", ["holiday_solicitation_fk_company_id", "holiday_solicitation_funcionary_fk_user_id", "holiday_solicitation_day_amount", "holiday_solicitation_initial_date", "holiday_solicitation_return_date", "holiday_solicitation_final_return_date"])

                this.setField("holiday_solicitation_fk_company_id", {
                    readonly: true
                });

                this.setField("holiday_solicitation_funcionary_fk_user_id", {
                    readonly: true
                });

                this.setField("holiday_solicitation_day_amount", {
                    readonly: true
                });

                this.setField("holiday_solicitation_initial_date", {
                    readonly: true
                });

                this.setField("holiday_solicitation_return_date", {
                    readonly: true
                });

                this.setField("holiday_solicitation_final_return_date", {
                    readonly: true
                });
            } else {
                this.setState("loadings", ["holiday_solicitation_fk_company_id", "holiday_solicitation_funcionary_fk_user_id", "holiday_solicitation_day_amount", "holiday_solicitation_initial_date", "holiday_solicitation_return_date", "holiday_solicitation_final_return_date"])

                this.setField("holiday_solicitation_fk_company_id", {
                    readonly: false
                });

                this.setField("holiday_solicitation_funcionary_fk_user_id", {
                    readonly: false
                });

                this.setField("holiday_solicitation_day_amount", {
                    readonly: false
                });

                this.setField("holiday_solicitation_initial_date", {
                    readonly: false
                });

                this.setField("holiday_solicitation_return_date", {
                    readonly: false
                });

                this.setField("holiday_solicitation_final_return_date", {
                    readonly: false
                });
            }

            this.setField("holiday_solicitation_acquisition_periods", {
                hidden: true
            })

            this.setField("holiday_solicitation_text_date", {
                hidden: false
            })
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 300)
    }
}