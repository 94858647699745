import { Event } from "../Event";

export class RequestLayoffTypeNoticeEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if(value === '1') {
            this.setState("loadings", ["request_layoff_start_date_notice", "request_layoff_final_date_notice"])

            this.setFieldValue("request_layoff_start_date_notice", "")
            this.setFieldValue("request_layoff_final_date_notice", "")

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)
        }
    }
}