import { Event } from "../Event";

export class ConsignedCreditAmountEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        const customInstallment = this.values.consigned_credit_custom_installment ? this.values.consigned_credit_custom_installment : [];

        let outstandingBalance = 0;

        if (customInstallment.length > 0) {
            this.setState("loadings", ["consigned_credit_outstanding_balance"])

            for (const item of customInstallment) {
                if (item.consigned_credit_installment_installment_paid === false) {
                    outstandingBalance = + item.consigned_credit_installment_parcel_value;
                } else {
                    continue;
                }
            }

            this.setFieldValue("consigned_credit_outstanding_balance", Number(outstandingBalance))

            setTimeout(() => {
                this.setState("loadings", [])
            }, 300)
        } else {
            this.setState("loadings", ["consigned_credit_outstanding_balance"])

            this.setFieldValue("consigned_credit_outstanding_balance", value)

            setTimeout(() => {
                this.setState("loadings", [])
            }, 300)
        }


    }
}