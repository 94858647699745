import axios from "../../utilities/axios";
import { Event } from "../Event";

export class UserEvents extends Event {
  // Método principal de carregamento
  public onLoad = async () => {
    const { url } = this.globalProps.match;
    const { user_is_manager: isManager, user_is_third: isThird, user_inactive_date: inactiveDate, user_management_fk_function_id: userFunction } = this.values || {};
    

    // Configurações de exibição quando visualizando
    if (url.includes("/view")) {
      this.setReadonlyFields(["user_active", "user_is_manager", "user_has_time_bank", "user_hour_flexibile"]);
      if (this.globalProps.values.user_hour_flexibile) {
        await this.viewData();
      }
    }

    // Configurações de edição
    if (this.globalProps.isEdit && this.globalProps.values.user_hour_flexibile) {
      await this.viewData();
    }

    if (url.includes("/add")) {
      this.setState("loadings", ["user_name", "user_password"])
        this.setFieldValue("user_name", "\u200E")
        this.setFieldValue("user_password", "\u200E")
      this.setState("loadings", [])
    }
      

    // Configura valores padrão para novos usuários
    if (this.globalProps.location.pathname.includes('/add')) {
      this.setFieldValue("user_hour_flexibile", false);
    }

  }

  // Configura campos como somente leitura
  private setReadonlyFields(fields: string[]) {
    fields.forEach(field => this.setField(field, { readonly: true }));
  }

  // Carregar dados relacionados ao usuário (horário flexível)
  async viewData(): Promise<void> {
    const fieldsToShow = [
      "user_personalizad", "user_hour_start", "user_hour_end", "user_hour_interval", "user_amount_hour_day",
      "user_div_friday", "user_hour_friday_start", "user_hour_friday_end", "user_hour_interval_friday", "user_amount_hour_friday",
      "user_div_saturday", "user_hour_saturday_start", "user_hour_saturday_end", "user_hour_interval_saturday", "user_amount_hour_saturday"
    ];

    fieldsToShow.forEach(field => this.setField(field, { hidden: false }));
  }

  // Configurações para usuário terceiro
  private handleThirdUser() {
    this.setState("loadings", [
      "user_is_manager", "user_has_time_bank", "user_hour_flexibile", "user_select_companies", "user_fk_base_id",
      "user_employee_code", "user_management_fk_function_id", "user_select_sectors", "user_manager", "user_fk_role_id",
      "user_admission_date", "user_inactive_date", "user_email", "user_password", "user_attachment", "user_name",
      "user_active", "user_cpf_cnpj", "user_is_third", "user_holidays", "user_bases", "user_custom_salary",
      "user_total_balance_hour", "user_historical_time_banks", "user_hour_start", "user_hour_end", "user_hour_interval", "user_amount_hour_day",
      "user_hour_friday_start", "user_hour_friday_end", "user_hour_interval_friday", "user_amount_hour_friday", "user_hour_saturday_start",
      "user_hour_saturday_end", "user_hour_interval_saturday", "user_amount_hour_saturday", "user_personalizad",
      "user_div_friday", "user_div_saturday"
    ]);

    const fields = {
      "user_is_third": { order: 5, width: 1, hidden: false, default: true, readonly: true },
      "user_name": { order: 10, width: 7 },
      "user_cpf_cnpj": { order: 20 },
      "user_active": { order: 30, width: 2 },
      // "user_checklist": { order: 40, width: 1, default: true, hidden: true },
      "user_has_time_bank": { required: false, hidden: true },
      "user_email": { required: false, hidden: true },
      "user_personalizad": { required: false, hidden: true },
      "user_total_balance_hour": { required: false, hidden: true },
      // "user_checklist_tables": { required: false, hidden: true },
      "user_div_saturday": { required: false, hidden: true },
      "user_amount_hour_day": { required: false, hidden: true },
      "user_div_friday": { required: false, hidden: true },
      "user_amount_hour_saturday": { required: false, hidden: true },
      "user_hour_saturday_end": { required: false, hidden: true },
      "user_hour_interval_saturday": { required: false, hidden: true },
      "user_hour_saturday_start": { required: false, hidden: true },
      "user_amount_hour_friday": { required: false, hidden: true },
      "user_hour_interval_friday": { required: false, hidden: true },
      "user_hour_friday_end": { required: false, hidden: true },
      "user_hour_friday_start": { required: false, hidden: true },
      "user_hour_interval": { required: false, hidden: true },
      "user_hour_end": { required: false, hidden: true },
      "user_hour_start": { required: false, hidden: true },
      "user_historical_time_banks": { required: false, hidden: true },
      "user_holidays": { required: false, hidden: true },
      "user_attachment": { required: false, hidden: true },
      "user_password": { required: false, hidden: true },
      "user_manager": { required: false, hidden: true },
      "user_inactive_date": { required: false, hidden: true },
      "user_admission_date": { required: false, hidden: true },
      "user_fk_role_id": { required: false, hidden: true },
      "user_bases": { required: false, hidden: true },
      "user_select_sectors": { required: false, hidden: true },
      "user_management_fk_function_id": { required: false, hidden: true },
      "user_employee_code": { required: false, hidden: true },
      "user_custom_salary": { required: false, hidden: true },
      "user_is_manager": { required: false, hidden: true },
      "user_hour_flexibile": { required: false, hidden: true },
      "user_select_companies": { required: false, hidden: true, disabled: true },
      "user_fk_base_id": { required: false, hidden: true }
    };

    Object.entries(fields).forEach(([key, value]) => this.setField(key, value));

    // this.setFieldValue("user_select_companies", [])

    setTimeout(() => this.setState("loadings", []), 500);
  }

  // Lidar com usuários inativos
  private handleInactiveUser(inactiveDate: any) {
    if (inactiveDate) {
      this.setState("loadings", ["user_active"]);
      this.setFieldValue("user_active", false);
      setTimeout(() => this.setState("loadings", []), 250);
    }
  }

  private handleReadonlyFields() {
    this.setState("loadings", ["user_active", "user_name", "user_cpf_cnpj", "user_is_third", "user_checklist", "user_checklist_tables"])

    this.setField("user_active", {
      readonly: true
    })

    // this.setField("user_checklist", {
    //   readonly: true
    // })

    // this.setField("user_checklist_tables", {
    //   disabled: true
    // })

    this.setField("user_name", {
      readonly: true
    })

    this.setField("user_cpf_cnpj", {
      readonly: true
    })

    this.setField("user_is_third", {
      readonly: true
    })

    setTimeout(() => {
      this.setState("loadings", [])
    }, 250)
  }

  // Exibição de base para gestores
  private handleBaseVisibility(isManager: boolean, isThird: boolean, isCheckin: boolean) {
    if (isCheckin) {
      return;
    }

    if (!isThird) {
      this.setState("loadings", ["user_fk_base_id", "user_bases"]);

      this.setField("user_fk_base_id", { hidden: isManager });
      this.setField("user_bases", { hidden: !isManager });

      setTimeout(() => this.setState("loadings", []), 250);
    }
  }

  // Carregar informações de função do usuário
  private async loadFunction(userFunction: any) {
    this.setState("loadings", ["user_management_fk_function_id"]);
    const functionName = await axios.get(`/api/v1/users/get_function_informations/${userFunction}`);
    this.setFieldValue("user_management_fk_function_id", userFunction);
    setTimeout(() => this.setState("loadings", []), 250);
  }

  // Gerenciamento da senha do usuário
  private async handlePassword() {
    const userPassword = this.values?.user_password;
    this.setState("loadings", ["user_password"]);

    this.setFieldValue("user_password", userPassword || "");

    setTimeout(() => this.setState("loadings", []), 300);
  }

  // Exibir ou ocultar campos de banco de horas
  private handleTimeBankVisibility() {
    const hasTimeBank = this.globalProps.values?.user_has_time_bank;

    if (!hasTimeBank) {
      this.setState("loadings", ["user_total_balance_hour", "user_historical_time_banks"]);
      this.setField("user_total_balance_hour", { hidden: true });
      this.setField("user_historical_time_banks", { hidden: true });
    }
  }

  // Gerenciar saldo de férias
  private handleHolidayBalance() {
    const userHolidayGrid = this.values.user_holidays;

    if (userHolidayGrid && userHolidayGrid.length > 0) {
      let remainingBalance = 0;
      let totalBalance = 0;

      userHolidayGrid.forEach((item: any) => {
        remainingBalance += Number(item.user_holiday_remaining_balance);
        totalBalance += Number(item.user_holiday_total_balance);
      });

      this.setState("loadings", ['user_total_balance', 'user_remaining_balance']);
      this.setFieldValue("user_total_balance", String(remainingBalance));
      this.setFieldValue("user_remaining_balance", String(totalBalance));

      setTimeout(() => this.setState("loadings", []), 300);
    }
  }
}

export class UserCepEvents extends Event {
  onBlur = (value: string, setField: any, setState: any) => {
    if (value.length === 8) {
      this.loadAddressData(value, setField, setState);
    }
  };

  private loadAddressData(value: string, setField: any, setState: any) {
    setState("loadings", ["user_address_street", "user_address_neighborhood", "user_fk_state_id", "user_fk_city_id"]);

    axios.get(`https://viacep.com.br/ws/${value}/json/`).then((res: any) => {
      if (res.data) {
        setField("user_address_street", res.data.logradouro);
        setField("user_address_neighborhood", res.data.bairro);

        this.loadStateAndCity(res.data.uf, res.data.ibge, setField, setState);
      }
    }).finally(() => setState("loadings", []));
  }

  private loadStateAndCity(uf: string, ibgeCode: string, setField: any, setState: any) {
    let stateId: string | undefined;

    axios.get("/api/v1/states/select", { params: { state: uf } })
      .then((res: any) => {
        const { label, value } = res.data.items[0] ?? {};
        stateId = value;
        if (label && value) setField("user_fk_state_id", { label, value: value.toString() });
      })
      .finally(() => {
        if (stateId) {
          axios.get("/api/v1/cities/select", { params: { state: stateId, city_ibge_code: ibgeCode } })
            .then((res: any) => {
              const { label, value } = res.data.items[0] ?? {};
              if (label && value) setField("user_fk_city_id", value);
            })
            .finally(() => setState("loadings", []));
        }
      });
  }
}

export class UserActiveEvents extends Event {
  public onChange = (value: any, setField: any, setState: any, formValues: any) => {
    const inactiveDate = this.values?.user_inactive_date;

    if (!value && !inactiveDate) {
      this.setInactiveDate(setField, setState);
    } else if (value && inactiveDate) {
      this.preventActiveWithInactiveDate(setField, setState);
    }
  };

  private setInactiveDate(setField: any, setState: any) {
    const dateNow = new Date();
    this.setState("loadings", ['user_inactive_date']);
    setField("user_inactive_date", dateNow);
    setTimeout(() => this.setState("loadings", []), 300);
  }

  private preventActiveWithInactiveDate(setField: any, setState: any) {
    this.setState("loadings", ["user_inactive_date"])

    setField("user_inactive_date", null);
  }
}

export class UserFkRoleIdEvents extends Event {
  public onChange = (value: any, setField: any, setState: any, formValues: any) => {
    // Código pode ser implementado aqui se necessário
  }
}