import { Event } from "../Event";
import { filter } from "lodash";

export class EntryExpenseEvents extends Event {

    public onLoad = () => {

        const url = this.globalProps.match.url;

        if (!url.includes("/add")) {

            this.setState("loadings", ["entry_expense_fk_company_id", "entry_expense_fk_user_id", "entry_expense_situation"])

            const haveToPayValue = this.values?.entry_expense_have_to_pay
            const isActive = this.values?.entry_expense_active

            if (isActive) {
                if (haveToPayValue === 0) {
                    this.setFieldValue("entry_expense_situation", "FINALIZADO")
                } else {
                    this.setFieldValue("entry_expense_situation", "ATIVO")
                }
            } else {
                this.setFieldValue("entry_expense_situation", "CANCELADO")
            }

            this.setField("entry_expense_fk_company_id", {
                readonly: true
            })

            this.setField("entry_expense_fk_user_id", {
                readonly: true
            })

            setTimeout(() => {
                this.setState("loadings", [])
            }, 250)

            const status = this.values?.entry_expense_active

            if (!status) {
                const entryExpenseReleaseds = this.values?.entry_expense_releaseds

                if (entryExpenseReleaseds) {
                    this.setState("loadings", ["entry_expense_releaseds", "entry_expense_active", "entry_expense_attachments", "entry_expense_obs", "entry_expense_cancellation_reason"])

                    this.setField("entry_expense_active", {
                        readonly: true
                    })

                    this.setField("entry_expense_cancellation_reason", {
                        readonly: true
                    })

                    this.setField("entry_expense_obs", {
                        readonly: true
                    })

                    this.setField("entry_expense_attachments", {
                        readonly: true
                    })

                    this.setField("entry_expense_releaseds", {
                        gridAdd: false,
                        gridEdit: false,
                        gridDelete: false
                    })

                    this.setState("loadings", [])
                }
            }
        } else {
            this.setState("loadings", ["entry_expense_cancellation_reason"])

            this.setField("entry_expense_cancellation_reason", {
                hidden: true
            })

            this.setState("loadings", [])
        }

        const { values } = this

        if (values?.entry_expense_custom_installment) {
            if (values.entry_expense_custom_installment.length > 0) {
                const { entry_expense_custom_installment } = values;
                const ifPaymentParcel = filter(entry_expense_custom_installment, { 'entry_expense_installment_installment_paid': true }).length > 0 ? true : false;
                if (ifPaymentParcel) {
                    this.setField('entry_expense_releaseds', {
                        gridAdd: false,
                        gridDelete: false,
                        gridEdit: false,
                        gridView: true
                    })
                }
            }
        }

    }
}