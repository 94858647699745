import axios from "../../utilities/axios";
import { Event } from "../Event";

export class UserRestrictsEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        try{

        } catch(e) {
            console.log('Error :::::> ', e)
        }
    }
}