import { Component, ReactNode } from "react";
import { Input, PanelProps, Table, DatePicker, InputGroup } from "rsuite";
import { CheckedCell } from "./CheckdCell";
import { NumberInput } from "../../NumberInput";

const { Cell, Column, HeaderCell } = Table;

interface IPanelInstallmentParcelProps extends PanelProps {
    data: Record<string, any>[];
    actionTable: ({ value, entry_expense_installment_id }: { value: boolean, entry_expense_installment_id: number }) => void;
    actionAddColumnTable: ({ value, entry_expense_installment_id }: { value: boolean, entry_expense_installment_id: number }) => void;
    disabledAll: boolean,
    isActive: boolean
}


export class TableInstallmentParcel extends Component<IPanelInstallmentParcelProps, any> {
    renderCheckColumn = ({ rowData }: { rowData: Record<string, any> }) => {
        return <CheckedCell
            value={rowData.entry_expense_installment_installment_paid}
            disabled={this.checkIfDisabled(
                {
                    rowData
                }
            )}
            handleActionOnChange={(value: boolean) => this.props.actionTable({ value, entry_expense_installment_id: rowData.entry_expense_installment_id })}
        />;
    }



    checkIfDisabled = ({ rowData }: { rowData: Record<string, any> }): boolean => {
        let booleanReturn = false;

        // if (rowData.entry_expense_installment_installment_paid || rowData.entry_expense_installment_move_paid || !this.props.isActive) {
        //     booleanReturn = true;
        // }

        if (!this.props.isActive) {
            booleanReturn = true;
        }
        if (this.props.disabledAll) {
            booleanReturn = true;
        }

        return booleanReturn;
    }


    render(): ReactNode {
        return (
            <Table
                data={this.props.data}
            >
                <Column width={100}>
                    <HeaderCell>Parcela</HeaderCell>
                    <Cell dataKey="entry_expense_installment_installment_number" />
                </Column>
                <Column width={250}>
                    <HeaderCell>Valor da Parcela</HeaderCell>
                    {/* <Cell>
                        {
                            (rowData: Record<string, any>) => {
                                return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(rowData.entry_expense_installment_parcel_value);
                            }
                        }
                    </Cell> */}
                    <Cell dataKey="entry_expense_installment_parcel_value" >
                        {(rowData: any, rowIndex: any) => {
                            return (
                                <>
                                    <div
                                        style={{ marginTop: "-7px" }}
                                    >

                                        <NumberInput
                                            disabled={this.checkIfDisabled(
                                                {
                                                    rowData
                                                }
                                            )}
                                            preffix={"R$"}
                                            punctuation={false}
                                            step={2}
                                            onChange={(value: any) => {
                                                this.props.data[rowIndex].entry_expense_installment_parcel_value = value;
                                            }}
                                            value={rowData.entry_expense_installment_parcel_value}
                                        />
                                    </div>
                                </>
                            );
                        }}
                    </Cell>
                </Column>
                <Column width={250}>
                    <HeaderCell>Vencimento</HeaderCell>
                    {/* <Cell>
                        {
                            (rowData: Record<string, any>) => {
                                return new Date(rowData.entry_expense_installment_parcel_date).toLocaleDateString();
                            }
                        }
                    </Cell> */}
                    <Cell dataKey="entry_expense_installment_parcel_date" >
                        {(rowData: any, rowIndex: any) => {
                            return (
                                <>
                                    <DatePicker
                                        style={{ marginTop: "-7px" }}
                                        disabled={this.checkIfDisabled(
                                            {
                                                rowData
                                            }
                                        )}
                                        format="DD/MM/YYYY"
                                        defaultValue={new Date(rowData.entry_expense_installment_parcel_date)}
                                        onChange={(e) => {
                                            this.props.data[rowIndex].entry_expense_installment_parcel_date = e;
                                        }}
                                    />
                                </>
                            );
                        }}
                    </Cell>
                </Column>
                <Column align="center" >
                    <HeaderCell>Já foi pago?</HeaderCell>
                    <Cell>
                        {(rowData: Record<string, any>) => (
                            this.renderCheckColumn({ rowData })
                        )}
                    </Cell>
                </Column>
                <Column width={175} align="center">
                    <HeaderCell>Mover para Ultima Parcela</HeaderCell>
                    <Cell>
                        {(rowData: Record<string, any>) => (
                            <CheckedCell
                                value={rowData.entry_expense_installment_move_paid}
                                disabled={this.checkIfDisabled(
                                    {
                                        rowData
                                    }
                                )}
                                // disabled={false}
                                handleActionOnChange={(value: boolean) => {


                                    this.props.actionAddColumnTable({ value, entry_expense_installment_id: rowData.entry_expense_installment_id })
                                }

                                }
                            />
                        )}
                    </Cell>
                </Column>
            </Table>
        )
    }
}