import { Notification } from "rsuite";
import axios from "../../utilities/axios";
import { Event } from "../Event";
// import { ConsultaCNPJ } from "./ConsultaCNPJ";

export class CompanyCnpjEvents extends Event {
  //init onFocusOut
  public onBlur = (value: any, setField: any, setState: any) => {
    if (value) {
      const fieldsLoading = [
        "company_email",
        "company_phone",
        "company_complement",
        "company_district",
        "company_number",
        "company_street",
        "company_fk_street_type_id",
        "company_fk_state_id",
        "company_fantasy",
        "company_abbreviation",
        "company_name",
        "company_zip_code",
        // "company_fk_city_id"
      ]

      setState("loadings", fieldsLoading);

      axios
        .get("/api/v1/consulta-cnpj/" + value)
        .then((res) => {
          var { data } = res.data;
          if (data.status === "ERROR") {
            // setState("loadings", []);
            return;
          }
          if (data) {
            if (data.fantasia.length) {
              setField("company_fantasy", data.fantasia);
            }
            var stateId: any = undefined;
            setField("company_name", data.nome);
            setField("company_zip_code", data.cep.split(".").join(""));
            axios
              .get("/api/v1/states/select", {
                params: {
                  state: data.uf,
                },
              })
              .then((res) => {
                const { items } = res.data;
                const { label, value } = items[0] ?? undefined;

                stateId = value;
                if (label && value) {
                  this.setFieldValue("company_fk_state_id", value.toString());
                }
              })
              .catch((e) => {
                this.setState("loadings", [])
              })
              .finally(() => {
                axios
                  .get("/api/v1/cities/select", {
                    params: {
                      search: data.municipio,
                      state: stateId,
                    },
                  })
                  .then((res) => {
                    var { items } = res.data;
                    var { label, value } = items[0] ?? undefined;

                    if (label && value) {
                      this.setFieldValue("company_fk_city_id", {label: label, value: value});
                      this.setState("loadings", ["company_fk_city_id"])
                    }
                  })
                  .catch((e) => {
                    this.setState("loadings", [])
                  })
                  .finally(() => {
                    setState("loadings", []);
                  });
              });

            setField("company_street", data.logradouro);
            setField("company_number", data.numero);
            setField("company_district", data.bairro);
            setField("company_complement", data.complemento);
            setField("company_phone", data.telefone.split(" ").join(""));
            setField("company_email", data.email);
          }
        })
        .catch((e) => {
          setState("loadings", []);
          Notification.error({
            title: "Erro",
            description: "Não foi possível consultar a esse CNPJ"
          })
        })
        .finally(() => {
          setState("loadings", []);
        });
    }
  };

}