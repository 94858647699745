import axios from "../../utilities/axios";
import { Event } from "../Event";

export class ConsignedCreditFkUserIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {

        if(value){
            const user = await axios.get("/api/v1/consigned_credit/users/get_informations/" + value)

            if(user){
                // console.log({user})
                this.setState("loadings", ["consigned_credit_fk_function_id"])

                this.setFieldValue("consigned_credit_fk_function_id", user.data?.user_management_fk_function_id)

                setTimeout(() => {
                    this.setState("loadings", [])
                }, 300)
            }
        }
    }
}