import React from "react";
import { withRouter } from "react-router-dom";
import { Event } from "../Event";
import { Button } from "rsuite";
import axios from "../../utilities/axios";
import { AxiosResponse } from "axios";


type State = {
    formValues: any;
    loading: boolean;
}

class ConsignedCreditFooterComponent extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            formValues: this.props.values.formValues
        };
    }

    printConsignedCredit = async () => {
        this.setState({
            loading: true,
        });

        try {
            const res = await axios.post("/api/v1/report/consigned_credit", {
                params: {
                    consigned_credit_id: this.state.formValues?.consigned_credit_id
                }
            })

            setTimeout(() => {
                window.open('https://' + window.location.host.split('25565').join('25567') + res.data.outputUrl, "_blank");
                this.setState({
                    loading: false,
                });
            }, 5000)

            // const res: AxiosResponse = await axios.get("/api/v1/consigned_credit/print", {
            //     params: {
            //         consigned_credit_id: this.state.formValues?.consigned_credit_id
            //     }
            // });

            // console.log({res})

            // window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");

        } catch (error) {
            console.error("Error printing consigned credit:", error);
        } finally {

        }
    }

    render(): React.ReactNode {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                    marginLeft: 8
                }}
            >
                <Button
                    color="blue"
                    style={{
                        display: 'flex',
                        flexDirection: "row",
                        gap: 10,
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    loading={this.state.loading}
                    onClick={this.printConsignedCredit}
                >
                    <i className="fas fa-print"></i>
                    Imprimir Avarias
                </Button>
            </div>
        );
    }
}

const ConsignedCreditFooterWithRouter = withRouter(ConsignedCreditFooterComponent);

export class ConsignedCreditFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <ConsignedCreditFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}