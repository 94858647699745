import { Event } from "../Event";

export class EntryExpenseCustomInstallmentEvents extends Event {

    // public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
    //     await this.setState('loadings',[
    //         'entry_expense_have_to_pay'
    //     ])

    //     this.setFieldValue('entry_expense_have_to_pay', 0);

    //     this.setState('loadings',[]);
    // }
}