import dayjs from "dayjs";
import { filter } from "lodash";
import { Component, ReactNode } from "react";
import { Button, Input, InputNumber, Notification, Popover, Whisper } from "rsuite";

interface IHeaderInstallmentProps {
    title: string;
    generateColumn: ({ updatedArray }: { updatedArray: Record<string, any>[] }) => void;
    info: string;
    dataValues: Record<string, any>[];
    parentValues: Record<string, any>;
    guid: string
}

interface IHeaderInstallmentState {
    numberRows: number | string;
    loading: boolean;
}

export class HeaderInstallment extends Component<IHeaderInstallmentProps, IHeaderInstallmentState> {
    state = {
        numberRows: '',
        loading: false
    }

    handleGenerateColumn = () => {
        this.setState({ loading: true })
        const { numberRows } = this.state
        if ([0, ''].includes(numberRows)) {
            Notification.warning(
                {
                    title: 'Atenção',
                    description: 'Quantidade de parcelas inválida!'
                }
            )
        }
        this.setState({ numberRows: '', loading: false })
        // this.props.generateColumn({ numberRows: ![0, ''].includes(numberRows) ? numberRows : 0 })
        this.addColumnRow({ numberRows: ![0, ''].includes(numberRows) ? numberRows : 0 })
    }



    addColumnRow = ({ numberRows }: { numberRows: number | string }) => {
        this.setState({
            loading: true
        });

        let number = parseInt(numberRows.toString());

        const { dataValues } = this.props;
        const { parentValues } = this.props;
        const updatedArray = [...dataValues];

        if (updatedArray.length > 0) {
            for (const item of updatedArray) {
                item.entry_expense_installment_disabled = true;
            }
        }

        const parcelNumberId = updatedArray.length > 0 ? updatedArray[updatedArray.length - 1].entry_expense_installment_parcel_number_id + 1 : 1;
        const startDate = dayjs().format('YYYY-MM-DD HH:mm:ss.SSS');

        let installments: number[] = [];

        const totalAmount = Math.round(parentValues.entry_expense_have_to_pay * 100) / 100;

        if (number === 1) {
            installments = [totalAmount]; // Se for apenas uma parcela, atribuímos o valor total.
        } else {
            const baseAmount = Math.floor((totalAmount / number) * 100) / 100;
            const remainder = Math.round((totalAmount - (baseAmount * number)) * 100) / 100;

            // Preenche as parcelas com o valor base
            installments = Array(number).fill(baseAmount);

            // Adiciona a diferença na última parcela
            installments[number - 1] = Math.round((installments[number - 1] + remainder) * 100) / 100;
        }

        for (let i = 0; i < number; i++) {
            const numberUnitParcel = (i + 1);
            const newDate = dayjs(startDate).add((i + 1), 'month').format('YYYY-MM-DD HH:mm:ss.SSS');
            let newItem: any = {
                entry_expense_installment_installment_paid: false,
                entry_expense_installment_installment_number: numberUnitParcel,
                entry_expense_installment_parcel_number_id: parcelNumberId,
                entry_expense_installment_parcel_date: newDate,
                entry_expense_installment_parcel_value: installments[i],
            };
            if (parentValues.entry_expense_id) {
                newItem = {
                    ...newItem,
                    entry_expense_installment_fk_entry_expense_id: parentValues.entry_expense_id
                };
            } else {
                newItem = {
                    ...newItem,
                    guid: this.props.guid
                };
            }
            if (numberUnitParcel === 1) {
                newItem = {
                    ...newItem,
                    entry_expense_installment_have_value: parentValues.entry_expense_have_to_pay
                };
            }
            updatedArray.push(newItem);
        }

        this.props.generateColumn({
            updatedArray
        });
    };


    doRenderComponent = (): boolean => {
        const { dataValues } = this.props;
        let isRender = false;
        if (dataValues.length > 0) {
            const maxValue = Math.max(...dataValues.map(item => item.entry_expense_installment_parcel_number_id));
            const lastObject = filter(dataValues, { 'entry_expense_installment_parcel_number_id': maxValue });
            isRender = filter(lastObject, { 'entry_expense_installment_installment_paid': false }).length === lastObject.length ? false : true;

        } else {
            isRender = true
        }
        return isRender
    }


    render(): ReactNode {
        return (
            <>
                <div
                    style={
                        {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }
                    }
                >
                    <div
                        style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 15
                            }
                        }
                    >
                        <h3>{this.props.title}</h3>
                        <Whisper speaker={
                            <Popover title="Informacão">
                                <div dangerouslySetInnerHTML={{ __html: (this.props.info) }}></div>
                            </Popover>
                        } trigger={"hover"} placement="top">
                            <i className="fas fa-info-circle"></i>
                        </Whisper>
                    </div>
                    {this.doRenderComponent() && (
                        <>
                            <div
                                style={
                                    {
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 15
                                    }
                                }
                            >
                                <InputNumber
                                    style={{ width: 210 }}
                                    placeholder="Quantidade de Parcelas"
                                    value={this.state.numberRows}
                                    onChange={(value: number | string) => this.setState({ numberRows: value })}
                                    min={0}
                                />
                                <Button
                                    color="green"
                                    onClick={this.handleGenerateColumn}
                                >
                                    Gerar Parcelas
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </>
        )
    }
}