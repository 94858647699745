import { Component, ReactNode } from "react";
import { Button, Input, InputNumber, Notification, Popover, Whisper } from "rsuite";

interface IHeaderInstallmentProps {
    title: string;
    generateColumn: ({ numberRows }: { numberRows: number | string }) => void;
    info: string;
    dataLength: number;
}

interface IHeaderInstallmentState {
    numberRows: number | string;
    loading: boolean;
}

export class HeaderInstallment extends Component<IHeaderInstallmentProps, IHeaderInstallmentState> {
    state = {
        numberRows: '',
        loading: false
    }


    handleGenerateColumn = () => {
        this.setState({ loading: true })
        const { numberRows } = this.state
        if ([0, ''].includes(numberRows)) {
            Notification.warning(
                {
                    title: 'Atenção',
                    description: 'Quantidade de parcelas inválida!'
                }
            )
        }
        this.setState({ numberRows: '', loading: false })
        this.props.generateColumn({ numberRows: ![0, ''].includes(numberRows) ? numberRows : 0 })
    }
    render(): ReactNode {
        return (
            <>
                <div
                    style={
                        {
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }
                    }
                >
                    <div
                        style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                gap: 15
                            }
                        }
                    >
                        <h3>{this.props.title}</h3>
                        <Whisper speaker={
                            <Popover title="Informacão">
                                <div dangerouslySetInnerHTML={{ __html: (this.props.info) }}></div>
                            </Popover>
                        } trigger={"hover"} placement="top">
                            <i className="fas fa-info-circle"></i>
                        </Whisper>
                    </div>
                    {this.props.dataLength > 0 ? (
                        <>
                            {/* <div
                                style={
                                    {
                                        alignContent: 'center',
                                    }
                                }
                            >
                                <text>
                                    Não é possivel gerar parcelas com parcelamento em vigor!
                                </text>
                            </div> */}
                        </>
                    ) : (
                        <div
                            style={
                                {
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 15
                                }
                            }
                        >
                            <InputNumber
                                style={{ width: 210 }}
                                placeholder="Quantidade de Parcelas"
                                value={this.state.numberRows}
                                onChange={(value: number | string) => this.setState({ numberRows: value })}
                                min={0}
                            />
                            <Button
                                color="green"
                                onClick={this.handleGenerateColumn}
                            >
                                Gerar Parcelas
                            </Button>
                        </div>

                    )}
                </div>
            </>
        )
    }
}