import React from "react";
import { withRouter } from "react-router-dom";
import { Event } from "../Event";
import { Button, Notification } from "rsuite";
import axios from "../../utilities/axios";
import { AxiosError, AxiosResponse } from "axios";

type State = {
    formValues: any;
    loading: boolean;
    reportLoadingButton: boolean;
}

class EntryExpenseFooterComponent extends React.Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            loading: false,
            formValues: this.props.values.formValues,
            reportLoadingButton: false
        };
    }

    generateReport = async () => {
        this.setState({
            reportLoadingButton: true,
        });

        try {
            const res = await axios.post("/api/v1/report/entry_expense", {
                params: {
                    entry_expense_id: this.state.formValues?.entry_expense_id
                }
            })

            setTimeout(() => {
                this.setState({
                    reportLoadingButton: false,
                });
                window.open('https://' + window.location.host.split('25565').join('25567') + res.data.outputUrl, "_blank");
            }, 5000)
        } catch (error) {
            console.error("Error printing entry expenses :::>> ", error);
        } finally {
            // this.setState({
            //     reportLoadingButton: false,
            // });
        }
    }

    printEntryExpense = async () => {
        this.setState({
            loading: true,
        });

        try {
            const res: AxiosResponse = await axios.get("/api/v1/entry_expense/print", {
                params: {
                    entry_expense_id: this.state.formValues?.entry_expense_id
                }
            });

            window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");

        } catch (error) {
            console.error("Error printing entry expense:", error);
        } finally {
            this.setState({
                loading: false,
            });
        }
    }

    cancelEntryExpense = () => {
        const cancellationReason = this.props.values.formValues?.entry_expense_cancellation_reason;

        if (!cancellationReason) {
            Notification.warning({
                title: "Aviso!",
                description: "Para cancelar preencha o campo de Motivo de Cancelamento!",
                placement: "topEnd",
                duration: 3000
            });

            return;
        }

        this.props.onSubmit(async () => {
            const entryExpenseId = this.props.values.formValues?.entry_expense_id;

            if (entryExpenseId) {
                try {
                    const res = await axios.post("/api/v1/entry_expenses/cancel_entry_expense", {
                        params: {
                            id: entryExpenseId
                        }
                    });
                } catch (error) {
                    console.error("Error cancelling entry expense:", error);
                }
            }
        });
    }

    render(): React.ReactNode {
        const isActive = this.props.values.formValues?.entry_expense_active;

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 8,
                    marginLeft: 8
                }}
            >
                <Button
                    color="blue"
                    style={{
                        display: 'flex',
                        flexDirection: "row",
                        gap: 10,
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    loading={this.state.loading}
                    onClick={this.printEntryExpense}
                >
                    <i className="fas fa-print"></i>
                    Impressão Diário de Viagem
                </Button>

                <Button
                    color="cyan"
                    style={{
                        display: 'flex',
                        flexDirection: "row",
                        gap: 10,
                        alignContent: "center",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                    loading={this.state.reportLoadingButton}
                    onClick={this.generateReport}

                >
                    <i className="fas fa-print"></i>
                    Imprimir Avarias
                </Button>

                {isActive && (
                    <Button
                        color="red"
                        style={{
                            display: 'flex',
                            flexDirection: "row",
                            gap: 10,
                            alignContent: "center",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        onClick={this.cancelEntryExpense}
                    >
                        <i className="fas fa-times"></i>
                        Cancelar
                    </Button>
                )}
            </div>
        );
    }
}

const EntryExpeseFooterWithRouter = withRouter(EntryExpenseFooterComponent);

export class EntryExpenseFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <EntryExpeseFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}