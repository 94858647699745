import { ArrowLeftLine, Reload, ViewsUnauthorize } from "@rsuite/icons";
import styles from "./styles.module.scss";
import React from "react";
import { Button, Icon, Input, InputGroup, InputPicker, Notification, Panel, SelectPicker } from "rsuite";
import axios from "axios";
import { parseCookies, setCookie } from "nookies";
import { MethodsUtils } from "../../../../core/utils/MethodsUtils";
import dayjs from "dayjs";

type Mark = {
    value: number | string;
    label: string;
}

type ChecklistTypes = {
    value: number | string;
    label: string;
}

type ChecklistTable = {
    value: number | string;
    label: string;
}

type Vehicle = {
    id: number;
    plate: string;
    markName: string;
    model: string;
}

type User = {
    id: number;
    name: string;
    cpfCnpj: string;
    userThird: boolean;
}

interface RegisterChecklistPageState {
    checkListType: number;
    hiddenVehicleField: boolean;
    hiddenFoundVehicle: boolean;
    hiddenNotFoundVehicle: boolean;
    hiddenTableChecklist: boolean;
    hiddenSelectUser: boolean;
    checklistTable: number;
    plate: string;
    readonlySelectType: boolean;
    readonlyInputPlate: boolean;
    readonlyNewPlate: boolean;
    readonlyNewMark: boolean;
    readonlyNewModel: boolean;
    readonlyTableChecklist: boolean;
    newPlate: string;
    newMark: string;
    newModel: string;
    marks: Mark[],
    driver: string;
    currentKM: number;
    checklistTypes: ChecklistTypes[];
    foundVehicle: Vehicle | null;
    checklistTables: ChecklistTable[];
    hiddenFinishToolBar: boolean;
    userObj: User | null;
}

interface CustomInputGroupWidthButtonProps {
    placeholder: string;
    onSearch: () => void;
    onPlateChange: (value: string) => void; // Função para atualizar a placa no estado
    plate: string;  // Valor da placa do estado do componente pai
    readonly: boolean;
}

const formatPlate = (value: string) => {
    // Remove caracteres que não sejam letras ou números
    let cleanValue = value.replace(/[^A-Za-z0-9]/g, '');

    // Verifica os segmentos para a placa de carro
    let letters = cleanValue.substring(0, 3).replace(/[^A-Za-z]/g, '');
    let firstNumber = cleanValue.substring(3, 4).replace(/[^0-9]/g, '');
    let letterOrNumber = cleanValue.substring(4, 5).replace(/[^A-Za-z0-9]/g, '');
    let lastNumbers = cleanValue.substring(5, 7).replace(/[^0-9]/g, '');

    // Cria o valor formatado sem adicionar o hífen permanentemente
    let formattedValue = `${letters}${firstNumber}${letterOrNumber}${lastNumbers}`;

    // Exibe o hífen na visualização, mas permite apagar sem interferência
    if (formattedValue.length > 3) {
        formattedValue = `${formattedValue.slice(0, 3)}-${formattedValue.slice(3)}`;
    }

    // Limita o tamanho da string formatada a 8 caracteres
    return formattedValue.toUpperCase().substring(0, 8);
};


const CustomInputGroupWidthButton: React.FC<CustomInputGroupWidthButtonProps> = ({ placeholder, onSearch, onPlateChange, plate, readonly, ...props }) => {

    const handleInputChange = (value: string) => {
        const formattedValue = formatPlate(value);  // Mantém a formatação
        onPlateChange(formattedValue);  // Passa a placa formatada para o componente pai
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSearch();  // Chama a função de busca ao pressionar "Enter"
        }
    };

    return (
        <InputGroup {...props} inside style={styles}>
            <Input
                placeholder={placeholder}
                value={plate}  // O valor do input é controlado pelo estado do componente pai
                onChange={handleInputChange}  // Formata e atualiza o valor ao digitar
                onKeyPress={handleKeyPress}
                disabled={readonly}
            />
            <InputGroup.Button onClick={onSearch}>
                <Icon icon="search" />
            </InputGroup.Button>
        </InputGroup>
    );
};


class RegisterChecklistPage extends React.Component<any, RegisterChecklistPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            checkListType: 1,
            hiddenVehicleField: false,
            hiddenFoundVehicle: true,
            hiddenNotFoundVehicle: true,
            hiddenTableChecklist: true,
            hiddenFinishToolBar: true,
            checklistTable: 0,
            plate: "",
            readonlySelectType: false,
            readonlyInputPlate: false,
            readonlyNewPlate: false,
            readonlyNewMark: false,
            readonlyNewModel: false,
            newPlate: "",
            newMark: "",
            newModel: "",
            marks: [],
            hiddenSelectUser: true,
            driver: "",
            currentKM: 0,
            readonlyTableChecklist: false,
            checklistTypes: [],
            foundVehicle: null,
            checklistTables: [],
            userObj: null
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handlePlateChange = this.handlePlateChange.bind(this);
        this.handleResetChecklist = this.handleResetChecklist.bind(this);
        this.handleGetMarks = this.handleGetMarks.bind(this);
        this.handleFinishRegisterVehicle = this.handleFinishRegisterVehicle.bind(this);
        this.handleOpenSelectNewUser = this.handleOpenSelectNewUser.bind(this);
        this.handleGetChecklistTables = this.handleGetChecklistTables.bind(this);
        this.handleGoToQuests = this.handleGoToQuests.bind(this);
    }

    handleReturnListChecklists(): void {
        window.location.href = '/checklist/list';
    }

    async handleGetMarks(): Promise<void> {
        try {
            const response = await axios.get("/checklist/getMarks/");

            const data = response.data

            this.setState({ marks: data });
        } catch (error) {
            console.error("Error fetching marks:", error);
        }
    }

    async handleGetChecklistTables(): Promise<void> {
        try {
            const response = await axios.get("/checklist/get_checklist_tables/" + this.state.userObj?.id)

            if (response.data) {
                this.setState({
                    checklistTables: response.data
                })

                if (response.data.length === 1) {
                    const index = response.data[0].value

                    this.setState({
                        checklistTable: index,
                        readonlyTableChecklist: true
                    })
                }
            }
        } catch (error) {
            console.log({ error })
        }
    }

    handleResetChecklist(): void {
        this.setState({
            checkListType: 1,
            hiddenVehicleField: false,
            hiddenFoundVehicle: true,
            hiddenFinishToolBar: true,
            hiddenNotFoundVehicle: true,
            hiddenTableChecklist: true,
            checklistTable: 0,
            plate: "",
            readonlySelectType: false,
            readonlyInputPlate: false,
            readonlyNewPlate: false,
            readonlyNewMark: false,
            readonlyNewModel: false,
            newPlate: "",
            newMark: "",
            newModel: "",
            marks: [],
            hiddenSelectUser: true,
            driver: "",
            currentKM: 0,
            readonlyTableChecklist: false,
            checklistTypes: [],
            foundVehicle: null,
            checklistTables: [],
            userObj: null
        })
    }

    async componentDidMount(): Promise<void> {
        const cookies = parseCookies();
        const cookieValue = cookies.checklist_driver_name;
        const cookieChecklistInformations = cookies["@checklist-informations"];
        const lastVehicle = cookies["@lastVehicle"];

        if (lastVehicle) {
            this.setState({
                plate: lastVehicle
            })

            setTimeout(() => {
                this.handleSearch()

            }, 250)
        }

        if (cookieChecklistInformations) {
            const parseJSONChecklistInformations = JSON.parse(cookieChecklistInformations)
            const checklistTypeSelected = this.state.checklistTypes.find(item => item?.label === parseJSONChecklistInformations?.type)

            this.setState({
                hiddenFinishToolBar: false
            })

            if (checklistTypeSelected?.value) {
                this.setState({
                    checkListType: Number(checklistTypeSelected.value)
                })
            }

            if (parseJSONChecklistInformations?.checklistTableId) {
                this.setState({
                    checklistTable: parseJSONChecklistInformations.checklistTableId,
                    hiddenTableChecklist: false
                })
            }

            if (parseJSONChecklistInformations?.vehicleAlreadyExists) {
                const formattedPlate = formatPlate(parseJSONChecklistInformations.vehicle?.plate)

                this.setState({
                    plate: formattedPlate,
                    hiddenFoundVehicle: false,
                    foundVehicle: {
                        id: 0,
                        plate: parseJSONChecklistInformations.vehicle?.plate,
                        model: parseJSONChecklistInformations.vehicle?.model,
                        markName: parseJSONChecklistInformations.vehicle?.mark
                    },
                    readonlyInputPlate: true
                })
            } else {
                await this.handleGetMarks()
                const formattedPlate = formatPlate(parseJSONChecklistInformations.vehicle?.plate)

                this.setState({
                    plate: formattedPlate,
                    hiddenFoundVehicle: true,
                    newMark: parseJSONChecklistInformations.vehicle?.markId,
                    newModel: parseJSONChecklistInformations.vehicle?.model,
                    readonlyInputPlate: true,
                    hiddenNotFoundVehicle: false
                })

                this.forceUpdate()

            }

            this.forceUpdate()
        }

        if (!cookieValue) {
            window.location.href = '/checklist';
            return;
        }

        const userObj = JSON.parse(cookieValue)

        if (cookieValue) {
            this.setState({
                driver: userObj.name,
                userObj: userObj
            })
        }

        const checklistTypes = await axios.get("/checklist/get_types")

        if (checklistTypes.data.length > 0) {
            this.setState({
                checklistTypes: checklistTypes.data
            })
        }

        this.handleGetChecklistTables()
    }

    handleFinishRegisterVehicle(): void {
        const { newPlate, newModel, newMark } = this.state;

        if (!newPlate || !newModel || !newMark) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha todos os campos para cadastrar um veículo",
                placement: "bottomEnd"
            })

            return
        }

        this.setState({
            readonlyNewPlate: true,
            readonlyNewMark: true,
            readonlyNewModel: true
        })

        Notification.success({
            title: "Sucesso!",
            description: "Sucesso!",
            placement: "bottomEnd"
        })
    }

    handleScrollOpen = () => {
        const selectPicker = document.querySelector('.tableSelect');

        if (selectPicker) {
            const selectBottomPosition = selectPicker.getBoundingClientRect().bottom + window.scrollY;

            const viewportHeight = window.innerHeight;

            if (selectBottomPosition < viewportHeight + window.scrollY) {
                window.scrollTo({
                    top: viewportHeight + window.scrollY,
                    behavior: 'smooth',
                });
            }
        }
    }

    handleGoToQuests(): void {
        const { userObj, checkListType, checklistTypes, foundVehicle, newPlate, newMark, newModel, checklistTable, plate } = this.state;
        
        const startCheck: string = dayjs(new Date()).subtract(3, 'hour').format("DD/MM/YYYY - HH:mm:ss").toString();


            setCookie(null, "@startChecklist", startCheck, {
                maxAge: 60 * 60,
                path: '/'
            })

        if (!checklistTable) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha a tabela de checklist para continuar!",
                placement: "topEnd"
            })

            return;
        }

        const typeText = checklistTypes.find((item: any) => item?.value === checkListType)

        let saveCookieObj: any;

        if (foundVehicle) {
            const formattedPlate = foundVehicle?.plate.replace(/[^a-zA-Z0-9]/g, "").toUpperCase()

            saveCookieObj = {
                userId: userObj?.id,
                userName: userObj?.name,
                userCpfCnpj: userObj?.cpfCnpj,
                type: typeText?.label,
                vehicleAlreadyExists: true,
                vehicle: {
                    plate: formattedPlate,
                    mark: foundVehicle?.markName,
                    model: foundVehicle?.model
                },
                checklistTableId: checklistTable
            }
        } else {
            const formattedPlate = plate.replace(/[^a-zA-Z0-9]/g, "").toUpperCase()

            const mark = this.state.marks.find((item: any) => item?.value === newMark)

            saveCookieObj = {
                userId: userObj?.id,
                userName: userObj?.name,
                userCpfCnpj: userObj?.cpfCnpj,
                type: typeText?.label,
                vehicleAlreadyExists: false,
                vehicle: {
                    plate: formattedPlate,
                    mark: mark?.label,
                    markId: mark?.value,
                    model: newModel,
                },
                checklistTableId: checklistTable
            }
        }

        const saveCookieJsonStringfy = JSON.stringify(saveCookieObj)

        setCookie(null, "@checklist-informations", saveCookieJsonStringfy, {
            maxAge: 60 * 60,
            path: '/',
        })

        window.location.href = '/checklist/answer';
    }

    handleCleanVehicle = () => {
        this.setState({
            plate: "",
            newPlate: "",
            newMark: "",
            newModel: "",
            hiddenFoundVehicle: true,
            hiddenNotFoundVehicle: true,
            readonlyInputPlate: false
        })
    }

    handleNewPlate = (value: string) => {
        const formattedValue = formatPlate(value);
        this.setState({
            newPlate: formattedValue
        })
    };

    handleSelectType(): void {
        this.setState({
            hiddenVehicleField: false
        });
    }

    handleOpenSelectNewUser(): void {
        // this.setState({
        //     hiddenSelectUser: false,
        //     readonlyTableChecklist: true
        // })
    }

    async handleSearch(): Promise<void> {
        if (!this.state.plate || this.state.plate.length !== 8) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha uma placa válida para continuar.",
                placement: "bottomEnd"
            })

            return;
        }

        const { plate } = this.state
        const formattedPlate = await MethodsUtils.UnformatedPlate(plate);


        let foundPlate: boolean;

        let vehicleInformations: any;
        try {
            vehicleInformations = await axios.get("/checklist/get_vehicle_information", {
                params: {
                    plate: formattedPlate,
                    userIsThird: this.state.userObj?.userThird
                }
            })
            if (vehicleInformations.data.canUseVehicle === false) {
                Notification.error({
                    title: "Erro!",
                    description: "Veículo não está disponível para este usuário.",
                    placement: "bottomEnd"
                })

                return;
            }

            if (vehicleInformations?.data) {
                foundPlate = true
            } else {
                foundPlate = false
            }
        } catch (error) {
            foundPlate = false
            console.log({ error })
        }

        if (foundPlate) {
            this.setState({
                hiddenFoundVehicle: false,
                hiddenTableChecklist: false,
                readonlyInputPlate: true,
                foundVehicle: vehicleInformations.data,
                hiddenSelectUser: false,
                hiddenFinishToolBar: false
            });
        } else {
            this.setState({
                readonlyInputPlate: true,
                hiddenNotFoundVehicle: false,
                hiddenTableChecklist: false,
                hiddenSelectUser: false,
                hiddenFinishToolBar: false,
                foundVehicle: null
            });
        }
    }

    handlePlateChange(value: string): void {
        // Atualiza o valor da placa no estado, mantendo a formatação
        this.setState({
            plate: value
        });
    }

    render() {
        return (
            <>
                <div className={styles.header}>
                    <div className={styles.headerContainer} onClick={this.handleReturnListChecklists}>
                        <ArrowLeftLine className={styles.arrow} onClick={this.handleReturnListChecklists} />
                        <span className={styles.returnPageText}>Voltar ao Início</span>
                    </div>
                    <h1 className={styles.userName}>Incluir Checklist</h1>
                </div>
                <div className={styles.container}>
                    <div className={styles.mainContainer}>
                        <div className={styles.headerButtonsContainer}></div>
                        <div className={styles.formContainer}>
                            <div className={styles.userInformations}>
                                <span style={{ fontFamily: 'Roboto, Helvetica Neue, Arial, sans-serif', fontSize: '20px', lineHeight: '1.5' }}><strong>Informações do Motorista</strong></span>
                                <Input value={this.state.driver} disabled />
                            </div>
                            <div className={styles.containerText}>
                                <span style={{ fontFamily: 'Roboto, Helvetica Neue, Arial, sans-serif', fontSize: '20px', lineHeight: '1.5' }}><strong>Informações do Checklist</strong></span>
                                {/* <Button appearance="primary" color="violet" onClick={this.handleResetChecklist}><Reload /></Button> */}
                            </div>
                            <div className={styles.form}>
                                <SelectPicker
                                    data={this.state.checklistTypes}
                                    placeholder="Selecione o tipo do checklist"
                                    style={{ width: "100%" }}
                                    value={this.state.checkListType}
                                    // defaultValue={1}
                                    onChange={(e) => {
                                        this.setState({ checkListType: e, readonlySelectType: true });
                                        // this.handleSelectType();
                                    }}
                                // disabled={this.state.readonlySelectType}
                                />

                                {!this.state.hiddenVehicleField && (
                                    <div className={styles.searchPlateContainer}>
                                        <CustomInputGroupWidthButton
                                            placeholder="Pesquise por uma placa..."
                                            onSearch={this.handleSearch}
                                            onPlateChange={this.handlePlateChange}
                                            plate={this.state.plate}
                                            readonly={this.state.readonlyInputPlate}
                                        />

                                        <Button appearance="primary" color="violet" onClick={this.handleCleanVehicle}><Icon icon="reload" /></Button>
                                    </div>
                                )}

                                {!this.state.hiddenFoundVehicle && (
                                    <Panel header={`Placa: ${this.state.plate}`} collapsible bordered>
                                        <div className={styles.cardText}>
                                            <span><strong>Placa:</strong> {this.state.plate}</span>
                                            <span><strong>Marca:</strong> {this.state.foundVehicle?.markName}</span>
                                            <span><strong>Modelo:</strong> {this.state.foundVehicle?.model}</span>
                                        </div>
                                    </Panel>
                                )}

                                {!this.state.hiddenNotFoundVehicle && (
                                    <Panel header={"Veículo não encontrado!"} collapsible bordered defaultExpanded={true}>
                                        <div className={styles.cardText}>
                                            <span className={styles.registerVehicleText}>Cadastre o seu veículo</span>
                                            <span><strong>Placa:</strong> <Input value={this.state.plate} disabled /></span>
                                            <span><strong>Marca:</strong>
                                                <br />
                                                <SelectPicker
                                                    data={this.state.marks}
                                                    placeholder="Selecione uma marca..."
                                                    onOpen={this.handleGetMarks}
                                                    style={{ width: "100%" }}
                                                    disabled={this.state.readonlyNewMark}
                                                    value={this.state.newMark}
                                                    onChange={(e) => {
                                                        this.setState({ newMark: e })
                                                        console.log({ e })
                                                    }}
                                                />
                                            </span>
                                            <span><strong>Modelo:</strong> <Input onChange={(e) => this.setState({ newModel: e })} value={this.state.newModel} disabled={this.state.readonlyNewModel} /></span>
                                        </div>
                                        {/* <Button appearance="primary" color="green" style={{ marginTop: '1rem' }} onClick={this.handleFinishRegisterVehicle}>Finalizar</Button> */}
                                    </Panel>
                                )}

                                {!this.state.hiddenTableChecklist && (
                                    <>
                                        <div className={styles.containerText2}>
                                            <span style={{ fontFamily: 'Roboto, Helvetica Neue, Arial, sans-serif', fontSize: '20px', lineHeight: '1.5' }}><strong>Tabelas de Checklist</strong></span>
                                        </div>

                                        <SelectPicker
                                            data={this.state.checklistTables}
                                            placeholder="Selecione uma tabela checklist..."
                                            className="tableSelect"
                                            style={{ width: "100%" }}
                                            value={this.state.checklistTable}
                                            onOpen={this.handleScrollOpen}
                                            onChange={(e) => {
                                                this.setState({ checklistTable: e });
                                            }}
                                            disabled={this.state.readonlyTableChecklist}
                                        />
                                    </>
                                )}

                                {!this.state.hiddenFinishToolBar && (
                                    <>
                                        <div className={styles.containerToolBar}>
                                            <Button appearance="primary" color="cyan" style={{ width: '100%' }} onClick={this.handleGoToQuests}>Ir para questionário</Button>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}

export default RegisterChecklistPage;
