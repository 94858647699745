import React from "react";
import { Button, Tooltip, Whisper } from "rsuite";
import { ModalExportHours } from "./components/ModalExportHours";



interface ImportHoursExportState {
    show: boolean;
}

export class ImportHourExportBrowserlize extends React.Component<any, ImportHoursExportState> {
    state = {
        show: false,
    }

    onOpenModal() {
        this.setState({ show: true });
    }

    onCloseModal() {
        this.setState({show: false})
    }

    render(): React.ReactNode {
        return (
            <>
                <ModalExportHours
                    showModal={this.state.show}
                    onClose={() =>this.onCloseModal()}
                />
                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Exportação para a Contabilidade</Tooltip>}>
                    <Button style={
                        {
                            backgroundColor: '#dcdcdc'
                        }
                    }
                        className="mr-2"
                        onClick={() => this.onOpenModal(

                        )}
                    >
                        {/* <i className="fas fa-fw fa-cogs"></i> */}
                        {/* <img
                            style={
                                {
                                    width: 20,
                                }
                            }
                            alt="Imagem da logo do ponto mais"
                            // src={LogoPontoMais}
                        /> */}
                        <i className="fas fa-balance-scale"></i>
                    </Button>
                </Whisper>
            </>
        )
    }
}