import React, { Component } from "react";
import { ControlLabel, FormGroup, SelectPicker, Notification } from "rsuite";
import axios from "../../utilities/axios";

interface selectProps {
    value: string;
    label: string;
}

interface State {
    data: selectProps[];
    selectedValue: string;
}

export class SelectCustomSector extends Component<any, State> {

    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            selectedValue: ''
        };
        this.fetchSectors = this.fetchSectors.bind(this); // Ensuring fetchSectors is bound to this
    }

    componentDidMount() {
        if (this.props.values?.job_request_fk_user_id) {
            this.getFirstLoadData();
            this.fetchSectors();
        }
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.values?.job_request_fk_user_id !== prevProps.values?.job_request_fk_user_id) {
            this.getFirstLoadData();
            this.fetchSectors();
        }
    }

    async getFirstLoadData() {
        const { job_request_id } = this.props.values;
        if (job_request_id) {
            const response = await axios.get(`/api/v1/job_request/get_first_data/${job_request_id}`);

            this.setState({
                selectedValue: response.data.value
            });
        }
    }

    fetchSectors() {
        const selectedUserId = Number(this.props.values?.job_request_fk_user_id);

        if (selectedUserId) {
            axios.get("/api/v1/job_requests/get_sector_informations", {
                params: {
                    user_id: selectedUserId
                }
            }).then((response: any) => {
                this.setState({ data: response.data });
            }).catch((error: any) => {
                console.error("Error fetching sector information", error);
            });
        } else {
            Notification.warning({
                title: "Aviso!",
                description: "Selecione um funcionário para selecionar um departamento"
            })
        }
    }

    handleChange = (value: string) => {
        this.setState({ selectedValue: value });
        this.props.values.job_request_sector = value;
    }

    render() {
        return (
            <FormGroup>
                <ControlLabel>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            width: '95%'
                        }}
                    >
                        Departamento Solicitante
                    </div>
                </ControlLabel>
                <div className="rs-form-control-wrapper">
                    <SelectPicker
                        data={this.state.data}
                        labelKey="label"
                        valueKey="value"
                        value={this.state.selectedValue}
                        onChange={this.handleChange}
                        onOpen={this.fetchSectors}
                        placeholder={"Selecione..."}
                        style={{ width: '100%' }}
                    />
                </div>
            </FormGroup>
        );
    }
}
