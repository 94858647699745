import axios from "../../utilities/axios";
import React from "react";
import { Button, Modal, Table, Panel, Toggle, DatePicker, SelectPicker } from "rsuite";
import { AxiosSelectPicker } from "../formfields/AxiosSelectPicker";
import dayjs from "dayjs";
import TablePagination from "rsuite/lib/Table/TablePagination";
import _ from "lodash"

const { Column, HeaderCell, Cell } = Table;

export class CustomModal extends React.Component<any, {}> {

    public state: any = {
        selectedNfe: [],
        itens: [],
        searchData: [],
        dateIni: dayjs().format('DD/MM/YYYY'),
        endDate: dayjs().add(7,'day').format('DD/MM/YYYY'),
        page: 1,
        dataTableNotes: this.props.omieData,
        loading: this.props.loadings
    }
    public locale = {
        sunday: "DON",
        monday: "SEG",
        tuesday: "TER",
        wednesday: "QUA",
        thursday: "QUI",
        friday: "SEX",
        saturday: "SAB",
        ok: "Ok",
        today: "Hoje",
        yesterday: "Ontem",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
    };

    handleChangePage = (page: any) => {
        this.setState({loading: true})
        // console.log('handle page ::: ', this.props.omieData)
        try{
            axios
            .post(`/api/v1/omie/pagination/${page}`,{
                data: this.props.omieData
            })
            .then((res) => {
                this.setState({
                    dataTableNotes: res.data.ResponseOmie,
                    
                })
            })
            .catch((err) => {console.log(err)})
            .finally(() => {
                this.setState({loading: false})
            })
            
        }catch(e){console.log(e)}
        this.setState({
            page: page
        });
    }
    handleChangeLength(dataKey:any) {
        this.setState({
            page: 1,
            displayLength: dataKey
        });
    }

    render() {
        if (this.props.show === false) {
            return null;

        }
        return (
            <Modal show={this.props.show} className={this.props.widthModal} width={this.props.widthNumber} onHide={this.props.closeModal}>
                <Modal.Header>
                    <h4>{this.props.title ?? "Deseja mesmo sair?"}</h4>
                </Modal.Header>
                <Modal.Body>
                    {this.props.body}
                </Modal.Body>
                <Modal.Footer>
                    {this.props.surfaceReport == true &&
                        <div style={{ display: "grid", flexDirection: "column", width: "150px", justifyContent: "space-between", textAlign: "start", alignContent: "center", marginBottom: "15px", position: "absolute" }}>
                            <label>Laudo Superficial</label>
                            <Toggle style={{ marginTop: "8px" }} />
                        </div>
                    }
                    {this.props.showConfirm !== false && <Button loading={this.props.loading} onClick={this.props.onConfirm} color={this.props.confirmColor ?? 'red'}>
                        {this.props.confirmText ?? 'Confirmar'}
                    </Button>}
                    {this.props.showInviteButton == true &&
                        <Button disabled={this.props.loading} onClick={this.props.inviteButton} color={"green"}>
                            Concluir
                        </Button>
                    }
                    <Button disabled={this.props.loading} loading={this.props.loading} onClick={this.props.onCancel} color={this.props.cancelColor ?? undefined}>
                        {this.props.cancelText ?? 'Cancelar'}
                    </Button>
                    {this.props.showbotaoclose == true && <Button disabled={this.props.loading} onClick={this.props.funcaoclose} color={"red"}>
                        Fechar
                    </Button>}

                </Modal.Footer>
            </Modal>
        );
    }
}
