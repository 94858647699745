import React from "react";
import { Modal, Progress } from "rsuite";
const { Line } = Progress

interface ProgressStatusProps {
    showModal: boolean;
    percentProgress: number
}

export class ProgressStatus extends React.Component<ProgressStatusProps,any> {


    // componentDidUpdate(prevProps: Readonly<{}>, prevState: Readonly<{}>, snapshot?: any): void {
    //     console.log({prevProps})
    //     console.log({prevState})
    //     console.log({snapshot})
    // }

    render(): React.ReactNode {
        return (
            <Modal
                show={this.props.showModal}
            >
                <Modal.Header closeButton={false}>
                    Progresso para sua importação
                </Modal.Header>
                <Modal.Body>
                    <Line 
                        percent={this.props.percentProgress}
                        strokeColor="blue"
                        strokeWidth={35}
                    />
                </Modal.Body>
            </Modal>
        )
    }
}