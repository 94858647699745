import React from "react";
import axios from "../../../../utilities/axios";
import { Button, Col, DatePicker, Loader, Panel, Placeholder, Row } from "rsuite";
import pluralize from "pluralize";
import dayjs from "dayjs";

export class ProjectDashboard extends React.Component<any, {}> {

    public state = {
        data: {
            total_transfer: '0',
            total_transfer_transit: '0',
            total_transfer_requested: '0',
            total_transfer_pending: '0'
        },
        loading: true,
        isHovered: false,
        totalNumberRequest: null,
        totalNumberShipping: null,
        totalNumberRequestPending: null,
        totalNumberShippingPending: null,
        dateIni: dayjs().startOf('month').format('YYYY-MM-DD 00:00:00.000'),
        endDate: dayjs().endOf('month').format('YYYY-MM-DD 23:59:59.999'),
        loadingSearch: false,
        showPlaceholder: false
    }

    componentDidMount(): void {
        this.onLoadingData()
    }

    onLoadingData = () => {
        this.setState({ loading: true})
        axios
            .get('/api/v1/projects/dashboard/itens')
            .then((response) => {
                this.setState({ data: response.data.items })
            })
            .finally(() => {
                this.setState({ loading: false })
            })
    }

    loadItemSelect = (column: any, columnIdFk: number, data: Record<string, any>) => {
        const aliasName = column.split('fk_').pop().split('_id').shift();
        const aliasNamePlural = pluralize.plural(aliasName)
        const columnId = `${aliasName}_id`;
        if (data[aliasNamePlural]) {
            return data[aliasNamePlural][`${aliasName}_name`]
        }
        return null

    }

    public locale = {
        sunday: "DON",
        monday: "SEG",
        tuesday: "TER",
        wednesday: "QUA",
        thursday: "QUI",
        friday: "SEX",
        saturday: "SAB",
        ok: "Ok",
        today: "Hoje",
        yesterday: "Ontem",
        hours: "Horas",
        minutes: "Minutos",
        seconds: "Segundos",
    };

    Card = (props: any) => (
        <Panel
            {...props}
            bordered
            header={<div style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%", whiteSpace: "nowrap", justifyContent: "center" }}>{props.title}</div>}
            style={{ borderRadius: 10, height: 180 }}
        >
            {this.state.showPlaceholder === true || !props.value ? (
                <Placeholder.Paragraph />
            ) : (
                <div style={{ display: "flex", marginTop: 20, alignItems: "center", justifyContent: "left" }}>
                    <h4>Quantidade: {props.value}</h4>
                </div>
            )}
        </Panel>
    );




    render(): React.ReactNode {
        if (this.state.loading) {
            return (
                <div className="row">
                    <div className="col-md-12">
                        <Panel bordered style={{ backgroundColor: "#fff", height: 135 }} className="rs-no-body d-flex justify-content-center allow-overflow" expanded>
                            <Loader size={"lg"} content="Carregando..." vertical></Loader>
                        </Panel>
                    </div>
                </div>
            );
        }
        return (
            <>
                {/* <Row style={{ margin: "0 0 20px 0" }}>
                    <Col md={5} sm={12}>
                        <label>Data Inicial</label>
                        <DatePicker
                            style={{ width: "100%" }}
                            defaultValue={new Date(this.state.dateIni)}
                            locale={this.locale}
                            format="DD/MM/YYYY"
                            onChange={(date: any) => {
                                this.setState({ dateIni: dayjs(date).format('YYYY-MM-DD 00:00:00.000') })
                            }}
                            placeholder="DD/MM/AAAA"
                        />
                    </Col>
                    <Col md={5} sm={12}>
                        <label>Data Final</label>
                        <DatePicker
                            style={{ width: "100%" }}
                            defaultValue={new Date(this.state.endDate)}
                            locale={this.locale}
                            format="DD/MM/YYYY"
                            onChange={(date: any) => {
                                this.setState({ endDate: dayjs(date).format('YYYY-MM-DD 23:59:59.999') })
                            }}
                            placeholder="DD/MM/AAAA"
                        />
                    </Col>
                    <Col md={3} sm={12} >
                        <Button
                            color="green"
                            loading={this.state.loadingSearch}
                            style={{ borderRadius: 6, width: '100%', height: "100%", fontWeight: "bold", color: "white", fontSize: 14, marginTop: 19 }}
                        // onClick={() => this.handleFilterDashboard()}
                        >Buscar
                        </Button>
                    </Col>
                </Row> */}
                <Row>
                    <Col md={5} sm={12}>
                        <this.Card title={"Qtd. Total de Solicitações"} value={this.state.data?.total_transfer} />
                    </Col>
                    <Col md={5} sm={12}>
                        <this.Card title={"Qtd. Total em Transito"} value={this.state.data?.total_transfer_transit} />
                    </Col>
                    <Col md={5} sm={12}>
                        <this.Card title={"Qtd. Total Aguar. Coleta"} value={this.state.data?.total_transfer_requested} />
                    </Col>
                    <Col md={5} sm={12}>
                        <this.Card title={"Qtd. Total Envios Pendentes"} value={this.state.data?.total_transfer_pending} />
                    </Col>
                    <Col md={4} sm={12} style={{ height: "100%" }}>
                        <Button
                            color="red"
                            loading={this.state.loading}
                            style={{ borderRadius: 10, width: '100%', height: 180, fontWeight: "bold", color: "white" }}
                        onClick={() => this.onLoadingData()}
                        ><i className="fas fa-sync-alt"></i>Atualizar
                        </Button>
                    </Col>
                </Row>
            </>
        )
    }
}