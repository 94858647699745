import { Component, ReactNode } from "react";
import { Toggle } from "rsuite";
import { Notification } from "rsuite";
import axios from "../../../../utilities/axios";

interface ICheckedCellProps {
    formValues: any
    value: boolean;
    handleActionOnChange: (
        { 
            value, 
            consigned_credit_installment_id, 
        }
            : 
        { 
            value: boolean, 
            consigned_credit_installment_id: number, 
        }
    ) => void;
    rowData: Record<string,any>
}

interface ICheckedCellState {
    value: boolean;
}


export class CheckedCell extends Component<ICheckedCellProps, ICheckedCellState> {
    state = {
        value: this.props.value,
        formValues: this.props.formValues
    }


    handleOnChange = (value: boolean) => {
        if(typeof this.props.rowData.consigned_credit_installment_id === 'number') {
            this.props.handleActionOnChange(
                {
                    value,
                    consigned_credit_installment_id: this.props.rowData.consigned_credit_installment_id ? this.props.rowData.consigned_credit_installment_id : this.props.rowData.guid
                }
            )
            this.setState(
                {
                    value
                }
            )
        } else {
            Notification.warning(
                {
                    title: "Aviso",
                    description: "Você precisa gravar para marcar o parcelamento"
                }
            )
        }

        this.changeOutstandingBalance()
    }

    changeOutstandingBalance = async () => {

        // const id = this.state.formValues.consigned_credit_id

        // const customInstallment = this.state.formValues.consigned_credit_custom_installment

        // let outstandingBalance: number = 0

        // for(const item of customInstallment) {
        //     if(item.consigned_credit_installment_installment_paid === false) {
        //         outstandingBalance += Number(item.consigned_credit_installment_parcel_value)
        //     }
        // }

        // await axios.post("/api/v1/consigned_credit/update_outstanding_balance", {
        //     params: {
        //         id: id,
        //         outstandingBalance: outstandingBalance
        //     }
        // })

    }

    render(): ReactNode {
        return (
            <>
                <Toggle
                    checked={this.state.value}
                    size="md"
                    // disabled={this.props.value === true}
                    onChange={this.handleOnChange}
                />
            </>
        )
    }
}