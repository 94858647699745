import { MethodsUtils } from "../../core/utils/MethodsUtils";
import { Event } from "../Event";

export class VehicleEvents extends Event {
    public onLoad = () => {
        this.setState("loading", ["vehicle_plate"])
        const plate = this.values?.vehicle_plate

        if (plate && !plate.includes("-")) {
            const formatPlate = MethodsUtils.FormatPlate(plate)
            if (formatPlate) {
                this.setFieldValue("vehicle_plate", formatPlate)
            }
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 250)
    }
}