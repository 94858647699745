import { ParametersGlobals } from "../../core/parametersGlobals/ParametersGlobals";
import { Event } from "../Event";
import { Notification } from "rsuite";

export class UserHourFlexibileEvents extends Event {

    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {

        const add: boolean = this.globalProps.match.url.includes('/add')

        if (value){
            Notification.warning({
            title: "Aviso!",
            description: 'Informe a CARGA HORÁRIA na aba JORNADA DIÁRIA',
            duration: 2500
          });
          await this.viewData();
          if (add){
              await this.fillData();
          }
          this.setState("loadings", [])
        
        } else {
          await this.hiddenData();
          this.setState("loadings", [])
        }
    }

    async fillData(): Promise<void> {
        const p = ParametersGlobals;
        // dias normais
        this.setFieldValue("user_hour_start", p.defaultHourStart)
        this.setFieldValue("user_hour_end", p.defaultHourEnd)
        this.setFieldValue("user_hour_interval", p.defaultHourInterval)
        this.setFieldValue("user_amount_hour_day", p.defaultHourAmountJourney);

        // sexta
        this.setFieldValue("user_hour_friday_start", p.defaultHourStart)
        this.setFieldValue("user_hour_friday_end", p.defaultHourEnd)
        this.setFieldValue("user_hour_interval_friday", p.defaultHourInterval)
        this.setFieldValue("user_amount_hour_friday", p.defaultHourAmountJourney);

        // sábado
        this.setFieldValue("user_hour_saturday_start", p.defaultHourStart);
        this.setFieldValue("user_hour_saturday_end", p.defaultHourEnd);
        this.setFieldValue("user_hour_interval_saturday", p.defaultHourInterval)
        this.setFieldValue("user_amount_hour_saturday", p.defaultHourAmountJourney);

    }
    async viewData():Promise<void> {
        // retira o hidden
        this.setField("user_personalizad", {hidden : false})
        this.setField("user_hour_start", {hidden: false})
        this.setField("user_hour_end", {hidden: false})
        this.setField("user_hour_interval", {hidden: false})
        this.setField("user_amount_hour_day", {hidden: false})

        // sexta
        this.setField("user_div_friday", {hidden: false})
        this.setField("user_hour_friday_start", {hidden: false})
        this.setField("user_hour_friday_end", {hidden: false})
        this.setField("user_hour_interval_friday", {hidden: false})
        this.setField("user_amount_hour_friday", {hidden: false})

        // sábado
        this.setField("user_div_saturday", {hidden: false})
        this.setField("user_hour_saturday_start", {hidden: false})
        this.setField("user_hour_saturday_end", {hidden: false})
        this.setField("user_hour_interval_saturday", {hidden: false})
        this.setField("user_amount_hour_saturday", {hidden: false})
    }

    async hiddenData():Promise<void> {
        // aplica o hidden
        this.setField("user_personalizad", {hidden : true})
        this.setField("user_hour_start", {hidden: true})
        this.setField("user_hour_end", {hidden: true})
        this.setField("user_hour_interval", {hidden: true})
        this.setField("user_amount_hour_day", {hidden: true})

        // sexta
        this.setField("user_div_friday", {hidden: true})
        this.setField("user_hour_friday_start", {hidden: true})
        this.setField("user_hour_friday_end", {hidden: true})
        this.setField("user_hour_interval_friday", {hidden: true})
        this.setField("user_amount_hour_friday", {hidden: true})

        // sábado
        this.setField("user_div_saturday", {hidden: true})
        this.setField("user_hour_saturday_start", {hidden: true})
        this.setField("user_hour_saturday_end", {hidden: true})
        this.setField("user_hour_interval_saturday", {hidden: true})
        this.setField("user_amount_hour_saturday", {hidden: true})

    }
}