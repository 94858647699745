import { Event } from "../Event";
import { Notification, Button, ButtonToolbar, Icon } from "rsuite";
import React from "react";

export class ImportHourEvents extends Event {

  enableEdit = () => {
    this.setState("loadings", []);
    this.setState("loadings", "import_hour_exported")
    this.setField("import_hour_normal_total_hours", {readonly: false})
    this.setField("import_hour_night_hours", {readonly: false})
    this.setField("import_hour_fifty_daytime", {readonly: false})
    this.setField("import_hour_night_extra_hours", {readonly: false})
    this.setField("import_hour_seventy_overtime", {readonly: false})
    this.setField("import_hour_hundred_overtime_day", {readonly: false})
    this.setField("import_hour_hundred_night_overtime", {readonly: false})
    this.setField("import_hour_additional_night", {readonly: false})
    this.setFieldValue("import_hour_exported", false);
    
    
    setTimeout(() => {
                    this.setState("loadings", [])
                    this.removeNotification(); 
                  },300)
    
  };

  
  showOverlay() {
    const overlay = document.createElement("div");
    overlay.id = "overlay";
    overlay.style.position = "fixed";
    overlay.style.top = "0";
    overlay.style.left = "0";
    overlay.style.width = "100%";
    overlay.style.height = "100%";
    overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    overlay.style.zIndex = "999"; // Garantir que o overlay fique acima de outros elementos
    document.body.appendChild(overlay);
  }

  
  hideOverlay() {
    const overlay = document.getElementById("overlay");
    if (overlay) {
      overlay.remove();
    }
  }

  removeNotification = () => {
    this.hideOverlay();
    Notification.closeAll();
  };

  public onLoad = () => {
    if (this.globalProps.isEdit) {
      
      if (this.globalProps.values.import_hour_exported) {
        this.showOverlay(); 

        Notification.open({
          title: (
            <div style={{ display: "flex", alignItems: "center" }}>
              <Icon
                icon="exclamation-circle"
                size="lg"
                style={{ marginRight: "5px", color: "red" }}
              />
              <h5 style={{ margin: 0 }}>REGISTRO JÁ EXPORTADO!!!</h5>
            </div>
          ),
          description: (
            <div>
              <h6>Caso deseje editar será necessário exportar novamente</h6>
              <br />
              <p>
                <h6>Deseja Continuar?</h6>
                <br />
              </p>
              <ButtonToolbar>
                <Button appearance="primary" onClick={this.enableEdit} 
                >
                  SIM
                </Button>
                <Button
                  color="red"
                  onClick={() => {
                    this.removeNotification();
                    this.hideOverlay();
                    window.history.back();
                  }}
                >
                  NÃO
                </Button>
              </ButtonToolbar>
            </div>
          ),
          onClose: (()=> this.removeNotification()),
          duration: 100000000,
        });

        
        setTimeout(() => {
          this.removeNotification();
          this.hideOverlay();
        }, 100000); 
      }
    }
  };
}
