import { Event } from "../Event";

export class ConsignedCreditEvents extends Event {
    public onLoad = () => {

        this.setField("consigned_credit_fk_function_id", {
            readonly: true
        })

        const url = this.globalProps.match.url

        if(!url.includes("/add")) {
            this.setField("consigned_credit_amount", {
                readonly: true
            })
        }
    }
}