import { Event } from "../Event";

export class GeneralParameterFkCompanyIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ["general_parameter_fk_base_id"])

        if (value) {
            this.setField("general_parameter_fk_base_id", {
                readonly: false
            })

            this.setFieldValue("general_parameter_fk_base_id", "")
        } else {
            this.setField("general_parameter_fk_base_id", {
                readonly: true
            })
        }

        setTimeout(() => {
            this.setState("loadings", [])
        }, 250)
    }
}