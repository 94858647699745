import classNames from "classnames";
import React from "react";
import { Tooltip, Whisper } from "rsuite";
import config from "../../globals";
import { UserContext } from "../authProvider/main";

class Shortcut extends React.Component<any, {}> {
    render() {
        return (
            <Whisper 
                trigger={"hover"}
                placement={"bottom"}
                delayHide={10}
                speaker={<Tooltip>
                    {this.props.module_label}
                </Tooltip>}
                >
                <div onClick={() => this.props.onClick(this.props.module_url)} className={classNames("module-shortcut", this.props.module_url)}>
                    <i className={classNames("fas fa-fw", "fa-" + this.props.module_icon)}></i>
                </div>
            </Whisper>
        );
    }
}

export class ModuleShortcuts extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;

    renderModules() {
        const modulesUser = this.context.data.modulesReturn;
        var output = [];
        for (let i in config.bussiness_unities) {
            let mod = config.bussiness_unities[i];
            if (mod.visible !== false) {
                output.push(<Shortcut onClick={this.props.onClick} key={"bu-" + i} {...mod} />);
            }
        }
        for (let i in modulesUser) {
            let mod = modulesUser[i];
            // if (mod.visible !== false) {
                output.push(<Shortcut onClick={this.props.onClick} key={i} {...mod} />);
            // }
        }

        return output;
    }

    render() {
        return (
            <div className="module-shortcuts">
                {this.renderModules()}
                {/* <i className="icon-cog"></i> */}
                {/* <Shortcut url={'laboratorio'} />
            <Shortcut url={'assistencia-tecnica'} /> */}
            </div>
        );
    }
}
