import dayjs from "dayjs";
import { Event } from "../../Event";

export class EntryExpenseReleasedInitialDateEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if(value) {
            this.setState("loadings", ["entry_expense_released_final_date", "entry_expense_released_quantity_days"])

            this.setField("entry_expense_released_final_date", {
                readonly: false
            })

            this.setFieldValue("entry_expense_released_final_date", null)
            this.setFieldValue("entry_expense_released_quantity_days", '')

            setTimeout(() => {
                this.setState("loadings", [])
            }, 200)
        }
    }
}