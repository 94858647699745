import axios from "../../utilities/axios";
import { Event } from "../Event";
import { ParametersGlobals } from "../../core/parametersGlobals/ParametersGlobals";
import dayjs from 'dayjs';
import { Notification } from "rsuite";

import { contentType } from "mime-types";
import { RolePermissions, UserPermission } from "../../core/enums/RolePermissions";

export class RoleEvents extends Event {
    
  public onLoad = async () => {
    if (this.globalProps.match.url.includes('add')) {
        this.setState("loadings", ["role_name", "role_active", "role_permissions"])        
        const results = await axios.get('/api/v1/me').then ((res) =>{
          return res.data
        } ).catch((e) => {
          console.log("error .:", e)
        })
        this.setState("loadings", []); 
        
        if (results.user.role.role_is_system_manager || results.user.user_id === UserPermission.USER_MASTER_NEC) {
            this.setField("role_is_system_manager", { hidden: false });
        }
    }


  }

  
}
