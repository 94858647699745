import { Event } from "../Event";

export class NationalHolidayTypeNEvents extends Event {

    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        const type: number = Number(value);
        this.setState("loadings", ["national_holiday_type"])
        switch (type) {
            case 1:
               this.setFieldValue("national_holiday_type", "FERIADO"); 
               break;

            case 2:
                this.setFieldValue("national_holiday_type", "PONTO FACULTATIVO");
                break;
            
            default:
                this.setFieldValue("national_holiday_type", "erro");
                break
        }
        
        this.setState("loadings", ["national_holiday_type"])
        
        setTimeout(()=>{
            this.setState("loadings", []);
        },200)

            

    }

}