import React from "react";
import { Button, Table, DatePicker, SelectPicker, Alert, Progress, Notification, Input, Checkbox, CheckTreePicker, InputNumber, FlexboxGrid } from "rsuite";
import axios from "../../utilities/axios";
import _, { filter } from "lodash";
import TablePagination from "rsuite/lib/Table/TablePagination";
import dayjs from "dayjs";
import { CheckPicker } from 'rsuite';
import axiosReport from "../../utilities/axiosReport";
import { CheckTreePickerAllOption } from "../formfields/custom/CheckTreePickerAllOption";

const { Column, HeaderCell, Cell } = Table;
export class ReportTransferRequest extends React.Component<any, {}> {
  public state: any = {
    dateIni: dayjs().subtract(30, 'days').format('MM/DD/YYYY'),
    endDate: dayjs().format('MM/DD/YYYY'),
    loading: false,
    showFieldsReport: [
      "transfer_request_collect_fk_user_id",
      "transfer_request_nf_number",
      "transfer_request_fk_service_type_id",
      "transfer_request_code",
      "transfer_request_fk_user_id",
      "transfer_request_fk_status_transfer_request_id",
      "transfer_request_creation_date",
      "transfer_request_nf_value",
      "transfer_request_vuupt_collect_id",
      "transfer_request_collect_date",
      "transfer_request_vuupt_delivery_id",
      "transfer_request_delivery_date"
    ],
    filterValues: {
      transfer_request_created_at_init: {
        "field": "transfer_request_created_at",
        "op": "gte",
        "value": dayjs().subtract(30, 'days').format('YYYY-MM-DD 00:00:00.000')
      },
      transfer_request_created_at_end: {
        "field": "transfer_request_created_at",
        "op": "lte",
        "value": dayjs().format('YYYY-MM-DD 23:59:59.999')
      },
      transfer_request_collect_fk_user_id: {
        "field": "transfer_request_collect_fk_user_id",
        "op": "",
        "value": ''
      },
      transfer_request_fk_user_id: {
        "field": "transfer_request_fk_user_id",
        "op": "",
        "value": ''
      },
      transfer_request_fk_service_type_id: {
        "field": "transfer_request_fk_service_type_id",
        "op": "",
        "value": ''
      },
      user_fk_project_id: {
        "field": "user_fk_project_id",
        "op": "",
        "value": ''
      },
    },
  }

  public locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
  };

  handleReport = async () => {
    this.setState({ loading: true })
    await axiosReport
      .post(`/api/v1/reports/generate`, { params: Object.values(this.state.filterValues), crud: "transfer_requests", type: "transfer_requests", showFieldsReport: this.state.showFieldsReport })
      .then((res) => {
        setTimeout(() => {
          window.open(`${window.location.protocol}//` + window.location.host.split('25565').join("25567") + res.data.outputUrl)
        }, 13000);
      })
      .catch((e) => {
        Notification.error({
          title: 'Erro!',
          description: e.response.data.message
        })
        this.setState({ loading: false });
      })
      .finally(() => {
        setTimeout(() => { this.setState({ loading: false }) }, 13000)
      })
  }

  onChange = (alias: any, field: any, op = "", value: any) => {
    // if (field === 'user_fk_project_id' && Array.isArray(value) && value.length === 0) {
    //   value = ''
    //   op = ''
    // }

    const fieldMappings: { [key: string]: any } = {
      'transfer_request_collect_fk_user_id': this.props.collectStore,
      'transfer_request_fk_user_id': this.props.requesterStore,
      'user_fk_project_id': this.props.projects,
      'transfer_request_fk_service_type_id': this.props.types
    };

    if (value.includes('all') && fieldMappings.hasOwnProperty(field)) {
      const allValues = fieldMappings[field].reduce((acc: any, node: any) => {
        if (node.children) {
          acc.push(...node.children.map((child: any) => child.value));
        } else {
          acc.push(node.value);
        }
        return acc;
      }, []);

      value = _.uniq(allValues);
    }
    this.setState({ filterValues: { ...this.state.filterValues, [alias]: { field: field, op: op, value: value } } })
  };

  componentDidMount() {
    const { store, requesterStore } = this.props;
    if (store === true && requesterStore && requesterStore.length > 0) {
        this.onChange("transfer_request_fk_user_id", "transfer_request_fk_user_id", "where", requesterStore[0].value);
    }
  }

  render() {
    const { projects, collectStore, requesterStore, types, store } = this.props
    return (
      <div>
        <FlexboxGrid justify="space-between" align="top">
          <FlexboxGrid.Item colspan={8}>
            <div>Loja de Coleta</div>
            <CheckTreePickerAllOption
              style={{ width: "96%", marginBottom: 20 }}
              preventOverflow={true}
              placement="bottom"
              value={this.state.filterValues.transfer_request_collect_fk_user_id.value}
              api={"api/v1/reports/collect-store"}
              defaultExpandAll
              onChange={(event: any) => {
                this.onChange("transfer_request_collect_fk_user_id", "transfer_request_collect_fk_user_id", "inlist", event)
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <div>Loja Solicitante</div>
            {store === false && (
              <CheckTreePickerAllOption
                style={{ width: "96%", marginBottom: 20 }}
                preventOverflow={true}
                placement="bottom"
                value={this.state.filterValues.transfer_request_fk_user_id.value}
                api={"api/v1/reports/requester-store"}
                defaultExpandAll
                onChange={(event: any) => {
                  this.onChange("transfer_request_fk_user_id", "transfer_request_fk_user_id", "inlist", event)
                }}
              />
            )}
            {store === true && requesterStore && requesterStore.length > 0 && (
                <CheckPicker
                  style={{ width: "96%", marginBottom: 20 }}
                  data={requesterStore}
                  placeholder={"Selecione..."}
                  valueKey="value"
                  labelKey="label"
                  defaultValue={store && store === true ? [requesterStore[0].value] : undefined}
                  disabled={store && store === true ? true : undefined}
                />
            )}
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <div>Projeto</div>
            {/* <CheckPicker
              style={{ width: "96%", marginBottom: 20 }}
              data={projects}
              placeholder={"Selecione..."}
              onChange={(event: any) => {
                this.onChange("user_fk_project_id", "user_fk_project_id", "custom", event)
              }}
            /> */}
            <CheckTreePickerAllOption
              style={{ width: "96%", marginBottom: 20 }}
              preventOverflow={true}
              placement="bottom"
              value={this.state.filterValues.user_fk_project_id.value}
              api={"api/v1/reports/projects"}
              defaultExpandAll
              onChange={(event: any) => {
                this.onChange("user_fk_project_id", "user_fk_project_id", "custom", event)
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <div>Tipo</div>
            <CheckTreePickerAllOption
              style={{ width: "96%", marginBottom: 20 }}
              preventOverflow={true}
              placement="bottom"
              value={this.state.filterValues.transfer_request_fk_service_type_id.value}
              api={"api/v1/reports/types"}
              defaultExpandAll
              onChange={(event: any) => {
                this.onChange("transfer_request_fk_service_type_id", "transfer_request_fk_service_type_id", "inlist", event)
              }}
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <div>Data Inicial</div>
            <DatePicker
              style={{ width: "96%" }}
              defaultValue={this.state.dateIni}
              locale={this.locale}
              format="DD/MM/YYYY"
              onChange={(date: any) => {
                this.onChange("transfer_request_created_at_init", "transfer_request_created_at", "gte", dayjs(date).format('YYYY-MM-DD 00:00:00.000'))
              }}
              placeholder="DD/MM/AAAA"
            />
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={8}>
            <div>Data Final</div>
            <DatePicker
              style={{ width: "96%" }}
              defaultValue={this.state.endDate}
              locale={this.locale}
              format="DD/MM/YYYY"
              onChange={(date: any) => this.onChange("transfer_request_created_at_end", "transfer_request_created_at", "lte", dayjs(date).format('YYYY-MM-DD 23:59:59.999'))}
              placeholder="DD/MM/AAAA"
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Button
          color="green"
          loading={this.state.loading}
          onClick={() => this.handleReport()}>Gerar Relatório
        </Button>
      </div>
    )
  }
}