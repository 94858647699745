import React from "react";
import { Whisper, Tooltip, Button, Notification } from "rsuite";
import LogoPontoMais from '../../../assets/isotipo-500x500.webp';
import AutoTracLogo from '../../../assets/AutotracLogo.png';
import { ModalImportAttachments } from "../ModalImportAttachments";
import { AutoTrackBody } from "./AutoTrackBody";
import { ModalBeforeImportAutoTrack } from "./AutoTrackComponents/ModalBeforeImportAutoTrack";
import { data } from "./mockDataBeforeModalAutotrack";
import { ProgressStatus } from "./AutoTrackComponents/ProgressStatus";
import axios from "../../../utilities/axios";
import { AxiosResponse } from "axios";
import { ModalBeforeImportPontoMais } from "./PontoMaisComponents/ModalBeforeImportPontoMais";


type state = {
    titleModal: string;
    showModal: boolean;
    functionName: string;
    allAttributes: Record<string, any>;
    showModalImportBefore: boolean;
    showModalImportBeforePontoMais: boolean;
    dataModalBefore: Record<string, any>[];
    showModalProgress: boolean;
    indexRepetition: number;
    percentProgress: number;
    uniqueIdImport: string;
}



export class ImportHourBrowserlize extends React.Component<any, state> {
    public state = {
        titleModal: '',
        showModal: false,
        functionName: '',
        allAttributes: {},
        showModalImportBefore: false,
        showModalImportBeforePontoMais: false,
        dataModalBefore: [],
        false: false,
        showModalProgress: false,
        percentProgress: 10,
        indexRepetition: 0,
        uniqueIdImport: ""

    }

    onOpenModal = ({ title, functionName }: { title: string, functionName: string }) => {
        this.setState({
            showModal: true,
            titleModal: title,
            functionName: functionName
        })
    }

    onCloseModal = () => {
        this.setState({
            showModal: false
        })
    }


    renderModalBody = () => {
        const { functionName } = this.state;
        if (functionName === 'importAutoTrack') {
            return <AutoTrackBody
                onChange={(value) => {
                    this.setState(
                        {
                            allAttributes: { ...this.state.allAttributes, accounting_parameter_id: value }
                        }
                    )
                }}
            />
        }
        return <></>
    }


    onProcesseArchive = (params: { data: Record<string, any> }) => {
        axios
            .get('/api/v1/import_hours/check-integration', {
                params: {
                    guid: params.data.uniqueId
                }
            })
            .then((response: AxiosResponse<{ status: string, items: Record<string, any>[] }>) => {
                if (response.data.items.length > 0) {
                    this.setState(
                        {
                            percentProgress: 100,
                            showModalProgress: false,
                            showModalImportBefore: true,
                            dataModalBefore: response.data.items,
                            uniqueIdImport: params.data.uniqueId
                        }
                    )
                } else {
                    this.setState(
                        {
                            percentProgress: this.state.percentProgress + 8,
                            indexRepetition: this.state.indexRepetition + 1
                        }
                    )
                    if (this.state.indexRepetition <= 10) {
                        setTimeout(() => {
                            this.onProcesseArchive(
                                {
                                    data: params.data
                                }
                            )
                        }, 5 * 1200)
                    } else {
                        Notification.error(
                            {
                                title: 'Erro no processamento de dados!',
                                description: 'Contatar o suporte da NEC Brasil para verificar o processamento dos dados!',
                                showModalProgress: false
                            }
                        )
                    }
                }
            })
            .catch((error) => {

            })
            .finally(() => {

            })
        this.setState(
            {
                showModalProgress: true,
                showModal: false
            }
        )
    }

    onCloseModalBefore () {
        if (this.state.functionName ==='importAutoTrack') {
            this.setState(
                {
                    showModalImportBefore: false
                }
            )
        } else if (this.state.functionName ==='importPontoMais'){
            this.setState(
                {
                    showModalImportBeforePontoMais: false
                }
            )
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <ProgressStatus
                    showModal={this.state.showModalProgress}
                    percentProgress={this.state.percentProgress}
                />
                
                <ModalBeforeImportAutoTrack
                    showModal={this.state.showModalImportBefore}
                    data={this.state.dataModalBefore}
                    guid={this.state.uniqueIdImport}
                    onClose={this.onCloseModalBefore.bind(this)}
                />

                <ModalBeforeImportPontoMais
                    showModal={this.state.showModalImportBeforePontoMais}
                    data={this.state.dataModalBefore}
                    guid={this.state.uniqueIdImport}
                    onClose={this.onCloseModalBefore.bind(this)}
                />
                
                <ModalImportAttachments
                    title={this.state.titleModal}
                    crud={this.props.name}
                    show={this.state.showModal}
                    functionNameImport={this.state.functionName}
                    onCloseModal={this.onCloseModal}
                    // modalBody={this.renderModalBody()}
                    allAttributes={this.state.allAttributes}
                    onProcessArchive={this.onProcesseArchive.bind(this)}
                />
                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Importação PontoMais</Tooltip>}>
                    <Button style={
                        {
                            backgroundColor: '#dcdcdc'
                        }
                    }
                        
                        className="mr-2"
                        onClick={() => this.onOpenModal(
                            {
                                title: 'Ponto Mais',
                                functionName: 'importPontoMais'
                            }
                        )}
                    >
                        {/* <i className="fas fa-fw fa-cogs"></i> */}
                        <img
                            style={
                                {
                                    width: 20,
                                }
                            }
                            alt="Imagem da logo do ponto mais"
                            src={LogoPontoMais}
                        />
                    </Button>
                </Whisper>
                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Importação Autotrac</Tooltip>}>
                    <Button style={
                        {
                            backgroundColor: '#dcdcdc'
                        }
                    }
                        className="mr-2"
                        onClick={() => this.onOpenModal(
                            {
                                title: 'AutoTrac',
                                functionName: 'importAutoTrack'
                            }
                        )}
                    >
                        {/* <i className="fas fa-fw fa-cogs"></i> */}
                        <img
                            style={
                                {
                                    width: 20,
                                }
                            }
                            alt="Imagem da logo do autoTRack"
                            src={AutoTracLogo}
                        />
                    </Button>
                </Whisper>
            </>
        )
    }
}