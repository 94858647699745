import React, { useState, useEffect, ChangeEvent } from "react";
import styles from "./styles.module.scss";

interface SalaryInputProps {
    match: {
        url: string;
    };
    values: {
        user_custom_salary: string;
    };
}

const SalaryInput: React.FC<SalaryInputProps> = ({ match, values }) => {
    const url = match.url;
    const [salary, setSalary] = useState<string>(values.user_custom_salary);
    const [hidden, setHidden] = useState<boolean>(true);

    useEffect(() => {
        setSalary(values.user_custom_salary);
    }, [values.user_custom_salary]);

    const handleChangeSalary = () => {
        setHidden(!hidden);
    };

    const handleSalary = (salary: string) => {
        values.user_custom_salary = salary;
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        setSalary(inputValue);
        handleSalary(inputValue);
    };

    return (
        <div className={styles.container}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "95%",
                }}
                className={styles.labelText}
            >
                Salário{" "}
                <span onClick={handleChangeSalary} className={styles.showText}>
                    <i className={`fa ${hidden ? "fa-eye" : "fa-eye-slash"}`}></i>
                </span>
            </div>

            {hidden && !url.includes("/add") && (
                <input
                    type="text"
                    disabled
                    className={styles.salaryInputHidden}
                    value="******"
                />
            )}

            {!hidden && !url.includes("/add") && (
                <input
                    type="text"
                    className={styles.salaryInputNotHidden}
                    value={salary}
                    onChange={handleInputChange}
                />
            )}

            {url.includes("/add") && (
                <input
                    type="text"
                    className={styles.salaryInput}
                    value={salary}
                    onChange={handleInputChange}
                />
            )}
        </div>
    );
};

export default SalaryInput;
