import React from "react";
import styles from "./styles.module.scss";
import { Button, Input, InputGroup, Notification } from "rsuite";
import axios from "axios";
import { setCookie, parseCookies, destroyCookie } from "nookies";
import { MethodsUtils } from "../../core/utils/MethodsUtils";
import classNames from "classnames";

interface ChecklistPageState {
    cpf: string;
    loadingButton: boolean;
    password: string;
    showPassword: boolean,
}

class ChecklistPage extends React.Component<any, ChecklistPageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            cpf: '',
            loadingButton: false,
            password: '',
            showPassword: false,
        };

        this.handleSubmitForm = this.handleSubmitForm.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputPasswordChange = this.handleInputPasswordChange.bind(this)
    }

    componentDidMount(): void {
        localStorage.removeItem("token")
    }

    changeLoadingButtonOff() {
        this.setState({
            loadingButton: false
        })
    }

    changeLoadingButtonOn() {
        this.setState({
            loadingButton: true
        })
    }

    async handleSubmitForm(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        
        // if (!this.state.password) {
        //     Notification.error({
        //         title: "Erro",
        //         description: "Senha não informada!",
        //         duration: 6000
        //     })

        //     // Adiciona a classe para destacar o campo
        //     const passwordField = document.getElementById("fieldPassword") as HTMLInputElement;
        //     if (passwordField) passwordField.classList.add("style.invalidField");

        //     passwordField?.focus();
        //     return;
        // } 

        // // Remove o destaque ao preencher
        // const passwordField = document.getElementById("fieldPassword") as HTMLInputElement;
        // if (passwordField) passwordField.classList.remove("invalid-field");
      
      
        this.changeLoadingButtonOn()
        destroyCookie(null, '@checklist-informations', {
            path: '/',
        });
      
        const unformattedCPF = this.state.cpf.replace(/\D/g, '');
      
        if (!unformattedCPF) {
            Notification.warning({
                title: "Aviso!",
                description: "Preencha o campo de CPF para prosseguir.",
                placement: "bottomEnd"
            })
            this.changeLoadingButtonOff()
            return
        }
      
        const user = await axios.get("/checklist/getUser/" + unformattedCPF);
      
        if (user.data.status !== 200) {
            Notification.error({
                title: "Erro!",
                description: "Usuário inativo, não habilitado para realizar o checklist ou não encontrado.",
                placement: "bottomEnd"
            })
            this.changeLoadingButtonOff()
            return;
        }
      
        const cookies = parseCookies();
        const cookieValue = cookies.checklist_driver_name;
      
        if (cookieValue) {
            destroyCookie(null, 'checklist_driver_name', {
                path: '/',
            });
        }
      
        const userObj = {
            id: user.data.user_id,
            name: user.data.user_name,
            cpfCpnj: unformattedCPF,
            userThird: user.data.user_third
        }
      
        const userObjStringfy = JSON.stringify(userObj)
      
        setCookie(null, "checklist_driver_name", userObjStringfy, {
            maxAge: 60 * 60,        // 1 hora
            path: '/',
        })
      
        this.changeLoadingButtonOff()
      
        Notification.success({
            title: "Sucesso!",
            description: "Login efetuado com sucesso.",
            placement: "bottomEnd"
        })
      
        setTimeout(() => {
            window.location.href = '/checklist/list';
        }, 1000)
  }  
  
    async handleInputChange(value: string) {
        const formattedCPF = await MethodsUtils.formatDocument(value);
        this.setState({ cpf: formattedCPF });
    }

    handleInputPasswordChange(value: string) {
        // Remove a classe quando o campo é preenchido
        const passwordField = document.getElementById("fieldPassword") as HTMLInputElement;
        if (value.trim() !== "") {
            passwordField?.classList.remove("invalid-field");
        }

        this.setState({password: value})
    }

    render() {
        return (
            <>
                <div className={styles.container}>
                    <img
                        src="correctBrasmegChecklistLogo.png"
                        alt="Logo Brasmeg"
                        className={styles.logo}
                    />
                    <div className={styles.containerForm}>
                        <form className={styles.form} onSubmit={this.handleSubmitForm}>
                            
                            <div className={styles.checklistTitle}>
                               <span><strong>CPF / CNPJ</strong></span>
                                <Input
                                    placeholder="Digite seu CPF/CNPJ..."
                                    value={this.state.cpf}
                                    onChange={(e) => this.handleInputChange(e)}
                                />
                                <br />
                                {/* <span><strong>SENHA</strong></span>

                                <InputGroup inside>
                                        <Input
                                            id="fieldPassword"
                                            autoFocus={true}
                                            placeholder="Digite sua senha"
                                            value={this.state.password}
                                            onChange={(e) => this.handleInputPasswordChange(e)}
                                            type={this.state.showPassword ? 'text' : 'password'}
                                        />
                                        <InputGroup.Button 
                                            onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                                            <i className={classNames("fas fa-fw", "fa-" + (!this.state.showPassword ? "eye" : "low-vision"))}></i>
                                        </InputGroup.Button>
                                    </InputGroup>
                                <div>
                                    <span>Campo Obrigatório</span>    
                                </div> */}
                            </div>
                            
                            
                            <Button
                                appearance="primary"
                                color="cyan"
                                loading={this.state.loadingButton}
                                type="submit">Acessar
                            </Button>

                        </form>
                    </div>
                </div>
            </>
        )
    }
}

export default ChecklistPage;